import React, {useState} from "react";
import NextButton from "../components/NextButton";
import ResponsiveIframe from "../components/ResponsiveIframe";
import { currentLocale } from '../constants/app.js';

const GAMES_URL = window.games_url;

function FireballGame() {
  const [finished, setFinished] = useState(false);
  const [triggerNextPage, updateTriggerNextPage] = useState(false);
  let scoreObj = { firepower: 30, cunning: 30 };

  const handlePostMessage = (data) => {
    if (data.id === "GAME_COMPLETE") {
      console.log("GAME_COMPLETE", data);
      
      setFinished(true);
    } 
    console.log("Iframe message ", data);
  };
  
  const handleMobleNextPage = () => {
    updateTriggerNextPage(true);
  }

  return (
    <>
      <ResponsiveIframe
        srcDoc={`${GAMES_URL}/fireballBlast/index.html?lang=${currentLocale}&cachebust=1`}
        handlePostMessage={handlePostMessage}
        handleMobleNextPage={handleMobleNextPage}
        height="370"
        width="657"
      />
      <NextButton active={finished} scoring={scoreObj} triggerNextPage={triggerNextPage} />
    </>
  );
}

export default FireballGame;
