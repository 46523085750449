import React from "react";
import styled from "styled-components";

const DragonTile = styled.div`
  /* border: solid 2px #97f7f9; */
  margin: 23px 17px;
  /* background: rgba(0, 0, 0, 0.3); */
  display: inline-block;
  width: 200px;
  max-width: 200px;
  height: 130px;
  vertical-align: top;
  img {
    max-width: 100px;
    // width: 10vw;
    margin-top: -40px;
  }
  h3 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 1.2rem;
  }
  // @media (max-width: 768px) {
  //   height: 100px;
  //   h3 {
  //     font-size: 1.4rem;
  //   }
  //   p {
  //     font-size: 1rem;
  //   }
  // }
  // @media (max-width: 568px) {
  //   height: 90px;
  //   h3 {
  //     font-size: 1.2rem;
  //   }
  //   p {
  //     font-size: 1rem;
  //   }
  }
`;

const DragonClass = ({ img, title, description }) => {
  return (
    <DragonTile className="dragonClass">
      <img
        className="dragonsLogo"
        alt=""
        src={require(`../images/icon_${img}.png`).default}
      />
      <h3>{title}</h3>
      <p>{description}</p>
    </DragonTile>
  );
};

export default DragonClass;
