import * as SHARABLE_IMAGE from '../constants/sharableImage';

export const generateSharableImage = (name, dragonClass, callback) => {
  const logoImage = new Image();
  logoImage.src = SHARABLE_IMAGE.LOGO;
  logoImage.crossOrigin = 'anonymous';
  logoImage.onload = function() {
    console.log('logo loaded');
    generateImage(name, dragonClass, callback, logoImage)
  };
};

const generateImage = (name, dragonClass, callback, logoImage) => {
  const baseImage = new Image();
  baseImage.src = SHARABLE_IMAGE.BG_URLS[dragonClass];
  baseImage.crossOrigin = 'anonymous';
  baseImage.onload = function() {
    console.log('bg image loaded');
    /*
      Setup the canvas element
    */
    const canvas = document.createElement('canvas');
    // const dpr = window.devicePixelRatio || 1;
    // Get the size of the canvas in CSS pixels.
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    // canvas.width = SHARABLE_IMAGE.BG_DEFAULT_WIDTH * dpr;
    // canvas.height = SHARABLE_IMAGE.BG_DEFAULT_HEIGHT * dpr;
    
    canvas.width = SHARABLE_IMAGE.BG_DEFAULT_WIDTH;
    canvas.height = SHARABLE_IMAGE.BG_DEFAULT_HEIGHT;
    const context = canvas.getContext('2d');
    // Scale all drawing operations by the dpr, so you
    // don't have to worry about the difference.
    // context.scale(dpr, dpr);

    // Add canvas to the DOM to draw on
    canvas.display = 'none';
    document.body.appendChild(canvas);
    context.webkitImageSmoothingEnabled = false;
    context.mozImageSmoothingEnabled = false;
    context.msImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;

    /*
      Draw elements
    */
    // add images
    context.drawImage(this, 0, 0);
    context.drawImage(logoImage, (canvas.width / 2 - logoImage.width / 2), 10);

    // add text
    // context.textAlign = "center";
    context.font = 'bold 62px din-condensed';
    context.fillStyle = 'white';
    // context.fillText(name, 0, 214);
    let nameTextWidth = context.measureText(name).width;
    context.fillText(name, (canvas.width / 2 - nameTextWidth / 2), 245);
    
    context.font = '34px din-condensed';
    context.fillStyle = 'white';
    let dargonTextWidth = context.measureText(SHARABLE_IMAGE.SHAREABLE_COPY.your_dragon_class_text).width;
    
    context.fillText(SHARABLE_IMAGE.SHAREABLE_COPY.your_dragon_class_text, (canvas.width / 2 - dargonTextWidth / 2), 340);

    context.font = '48px din-condensed';
    context.fillStyle = 'white';
    let classTextWidth = context.measureText(SHARABLE_IMAGE.SHAREABLE_COPY[dragonClass]).width;
    
    context.fillText(SHARABLE_IMAGE.SHAREABLE_COPY[dragonClass], (canvas.width / 2 - classTextWidth / 2), 900);

    /*
      trigger callback to set base64 to state
    */
    if (callback) {
      callback(canvas.toDataURL());
    }

    /*
      Remove canvas when done 
    */
    if (canvas.parentNode) {
      canvas.parentNode.removeChild(canvas);
    }
  };
}

