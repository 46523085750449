import React, { useState, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";

import { BUTTONS } from '../constants/copy';

import NextButton from "../components/NextButton";
import Button from "../components/Button";

import dragon from "../images/chicken_dragon.png";
import dragonFlying from "../images/chicken_moving.png";
import cliffFace from "../images/chicken_wall.png";
// import GameFrame from "../layout/GameFrame";
import GoButton from "../components/GoButton";
import { getDevice } from '../utils/deviceDetection';

const ChickenFrame = styled.div`
  padding: 40px 0;
  min-height: 300px;
  width: 60vw;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Flyer = styled.img`
  position: absolute;
  top: 10%;
  right: 20px;
  height: 80px;
`;

const Wall = styled.div`
  height: 100%;
  width: 120px;
  background-image: url(${cliffFace});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -90px;
  top: 0;
  z-index: 9;
`;

function ChickenGame() {
  const [timer, setTimer] = useState(0);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [scoreValue, setScoreValue] = useState(10);
  const countRef = useRef(null);
  const controls = useAnimation();
  const challengeDuration = getDevice() === 'smartphone' ? 2 : 3;
  let scoreObj = { bravery: scoreValue };

  const challengeStart = () => {
    setStarted(true);
    controls.start({
      x: 0,
      transition: {
        type: "linear",
        duration: challengeDuration,
        ease: "linear",
      },
    });
    countRef.current = setInterval(() => {
      setTimer((timer) => {
        if(Math.floor(timer + 0.1) === challengeDuration) {
          challengeEnd();
          return;
        }
        return timer + 0.1;
      });
    }, 100);
  };

  const challengeEnd = () => {
    //set finished, shows continue button
    setFinished(true);
    //stops scoring timer
    clearInterval(countRef.current);
    //begins inertia animation
    controls.start({
      x: 20,
      transition: { type: "inertia", velocity: 10 },
    });
    //stop animation (so it doesn't reset)
    setTimeout(() => {
      controls.stop();
    }, 1000);
    //scoring
    if (timer >= challengeDuration - 0) {
      setScoreValue(10);
      // console.log("fail");
    } else if (timer >= challengeDuration - 0.25) {
      // console.log("success");
      setScoreValue(30);
    } else if (timer >= challengeDuration - 0.5) {
      // console.log("success");
      setScoreValue(25);
    } else if (timer >= challengeDuration - 0.75) {
      // console.log("good effort");
      setScoreValue(20);
    } else if (timer >= challengeDuration - 1) {
      // console.log("good effort");
      setScoreValue(15);
    } else {
      // console.log("weak");
      setScoreValue(10);
    }
  };

  const handleActionToggle = () => {
    if (started) {
      challengeEnd();
    } else {
      challengeStart();
    }
  };

  return (
    <>
      <ChickenFrame>
        <GoButton challengeStart={handleActionToggle} />
        <motion.div animate={controls} initial={{ x: "-100%" }} style={{position: 'relative', zIndex: '10'}}>
          {started && !finished ? (
            <Flyer src={dragonFlying} />
          ) : (
            <Flyer src={dragon} />
          )}
        </motion.div>
        <Wall />
        {finished ? (
          <Button active={true} staticButton={true}>{BUTTONS.button_stopped}</Button>
        ) : !finished && started ? (
          <Button clickHandler={handleActionToggle}>{BUTTONS.button_stop}</Button>
        ) : (
          ""
        )}
      </ChickenFrame>
      <NextButton active={finished} scoring={scoreObj || 0} />
      {process.env.REACT_APP_WATCH && (
        <p>
          (Debug: Timer: {parseFloat(timer).toFixed(1)} Score: {scoreValue})
        </p>
      )}
    </>
  );
}

export default ChickenGame;
