/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { nextPage, updateScore } from "../state/actions";
import { GetState } from "../state/stateManager";
import { FaArrowCircleRight } from "react-icons/fa";

import ctaImg from "../images/cta.png";
import ctaActiveImg from "../images/cta-active.png";
import ctaFocusImg from "../images/cta-focus.png";

import { BUTTONS } from "../constants/copy";
import { playButtonAudio } from "../constants/app";


const StyledCTA = styled.span`
  display: block;
  padding: 20px;
  margin: 0 auto;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 2;
  font-family: 'din-condensed-bold';
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  cursor: pointer;
  position: relative;
  background-image: url(${ctaImg});

  svg {
    height: 30px;
    margin: 0 0 0px 5px;
  }
  .copy {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      padding: 13px 0;
    }
    
    &--small-font {
      font-size: 28px;
      
      svg {
        heght: 28px;
      }
    }
  }
  ${(props) =>
    props.disabled === "disabled" &&
    css`
      cursor: default;
      opacity: 0.6;
    `}
`;

const StyledGoState = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  background-image: url(${ctaActiveImg});
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.leaving === true ? "1" : "0")};
  
  ${StyledCTA}:hover & {
    opacity: 1;
  }
`;

const FocusArea = styled.span`
  display: block;
  padding: 20px;
  margin: 0 auto;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 3;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  cursor: default;
  position: relative;
  background-image: url(${ctaFocusImg});
  .copy {
    position: relative;
    z-index: 5;
  }
`;

function NextButton({ timeLeft, active = true, scoring = {}, copy = BUTTONS.button_next, triggerNextPage = false }) {
  const [state, dispatch] = GetState();
  const [leaving, setLeaving] = useState(false);

  const nextPageHandler = (e) => {
    playButtonAudio();
    setLeaving(true);
    setTimeout(() => {
      if (scoring) {
        for (const [key, value] of Object.entries(scoring)) {
          dispatch(updateScore(key, value, state.score));
        }
      }
      dispatch(nextPage(state.currentPage));
    }, 800);
  };
  
  useEffect(() => {
    if(triggerNextPage) {
      nextPageHandler();
    }
  }, [triggerNextPage]);

  let linkItem = "";
  if (active) {
    linkItem = (
      <StyledCTA onClick={nextPageHandler} id="next-button">
        <StyledGoState leaving={leaving}></StyledGoState>
        <span className={'copy' + (copy === 'Reveal my class' ? ' copy--small-font' : '')}>
          <span>{copy}</span>
          <FaArrowCircleRight />
        </span>
      </StyledCTA>
    );
  } else if (timeLeft) {
    linkItem = (
      <FocusArea>
        <span className="copy">{timeLeft}</span>
      </FocusArea>
    );
  } else {
    linkItem = (
      <StyledCTA id="next-button" disabled="disabled">
        <span className="copy">
          <span>{copy}</span>
          <FaArrowCircleRight />
        </span>
      </StyledCTA>
    );
  }

  return linkItem;
}

export default NextButton;
