/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import GoButton from "../components/GoButton";
import NextButton from "../components/NextButton";
import ResponsiveIframe from "../components/ResponsiveIframe";
import GameFrame from "../layout/GameFrame";
import { MAZE } from "../constants/copy";

const GAMES_URL = window.games_url;

function MazeChallenge() {
  const [timer, setTimer] = useState(40);
  const [isActive, setIsActive] = useState(false);
  const [finished, setFinished] = useState(false);
  const [score, setScore] = useState(10);
  const countRef = useRef(null);

  const handleTimerStart = () => {
    if (isActive) return;
    setIsActive(true);
    
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 0.01);
    }, 10);
  };

  const handleTimerEnd = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setFinished(true);
    //If they take less than 10 seconds, the user is awarded the maximum 30 cunning points. For 6-10 seconds they get 20 points. For 11+ seconds they get the minimum 10 points.
    if (timer >= 30) {
      setScore(30);
    } else if (timer >= 20) {
      setScore(20);
    }
  };

  const handlePostMessage = (data) => {
    const postData = JSON.parse(data);
    if (postData.message === "complete") {
      handleTimerEnd();
      if(document.getElementById('next-button')) {
        window.scrollTo(0, document.getElementById('next-button').offsetTop);
      }
    } else {
    }
  };

  const scoreObj = { cunning: score };
  
  useEffect(() => {
    if (timer <= 0) {
      handleTimerEnd();
    }
  }, [timer]);
  
  return (
    <>
      <GameFrame>
        <GoButton challengeStart={handleTimerStart} />
        <ResponsiveIframe
          srcDoc={`${GAMES_URL}/maze/index.html?start=${MAZE.start}&finish=${MAZE.finish}`}
          handlePostMessage={handlePostMessage}
          height="550"
          width="750"
          type="maze"
        />
      </GameFrame>
      <NextButton
        timeLeft={parseFloat(timer).toFixed(0)}
        active={finished}
        scoring={scoreObj}
      />
    </>
  );
}

export default MazeChallenge;
