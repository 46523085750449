export const PUZZLE_DEFAULT_SPACING = 20;

export const PUZZLE_PIECE_DEFAULT_WIDTH = 200;
export const PUZZLE_PIECE_DEFAULT_HEIGHT = 200;

export const PUZZLE_DEFAULT_WIDTH = 400;
export const PUZZLE_DEFAULT_HEIGHT = 400;

export const PUZZLE_CONTAINER_DEFAULT_WIDTH = {
  desktop: PUZZLE_DEFAULT_WIDTH + PUZZLE_PIECE_DEFAULT_WIDTH * 2,
  mobile: PUZZLE_DEFAULT_WIDTH
};
export const PUZZLE_CONTAINER_DEFAULT_HEIGHT = {
  desktop: PUZZLE_DEFAULT_HEIGHT,
  mobile: PUZZLE_DEFAULT_HEIGHT + PUZZLE_PIECE_DEFAULT_HEIGHT * 2
};

export const PUZZLE_COMPLETED_SOLTS = {
  slot_1: 'piece_4',
  slot_2: 'piece_2',
  slot_3: 'piece_3',
  slot_4: 'piece_1'
}

export const PUZZLE_PIECES_BY_IDS = ['piece_1', 'piece_2', 'piece_3', 'piece_4'];
export const PUZZLE_PIECES_POSITIONS = {
  piece_1 : {
    default_position: {
      desktop: {
        x: 0,
        y: 0
      },
      mobile: {
        x: 0,
        y: PUZZLE_DEFAULT_HEIGHT  + PUZZLE_DEFAULT_SPACING * 3
      }
    },
    container: {
      width: PUZZLE_PIECE_DEFAULT_WIDTH,
      height: PUZZLE_PIECE_DEFAULT_HEIGHT,
    },
    img_default: {
      img: require('../images/puzzle_pieces/puzzle_1.png').default,
      style: {
        top: '-76px',
        left: '-81px',
      }
    },
    img_hover: {
      img: require('../images/puzzle_pieces/puzzle_1_hover.png').default,
      style: {
        top: '-95px',
        left: '-94px',
      }
    },
  },
  piece_2 : {
    default_position: {
      desktop: {
        x: 0,
        y: PUZZLE_PIECE_DEFAULT_HEIGHT + PUZZLE_DEFAULT_SPACING * 2,
      },
      mobile: {
        x: 0,
        y: PUZZLE_DEFAULT_HEIGHT + PUZZLE_PIECE_DEFAULT_HEIGHT + PUZZLE_DEFAULT_SPACING * 4
      }
    },
    container: {
      width: PUZZLE_PIECE_DEFAULT_WIDTH,
      height: PUZZLE_PIECE_DEFAULT_HEIGHT,
    },
    img_default: {
      img: require('../images/puzzle_pieces/puzzle_2.png').default,
      style: {
        top: '-29px',
        left: '-81px',
      }
    },
    img_hover: {
      img: require('../images/puzzle_pieces/puzzle_2_hover.png').default,
      style: {
        top: '-48px',
        left: '-94px',
      }
    },
  },
  piece_3 : {
    default_position: {
      desktop: {
        x: PUZZLE_CONTAINER_DEFAULT_WIDTH.desktop - PUZZLE_PIECE_DEFAULT_WIDTH + PUZZLE_DEFAULT_SPACING * 2,
        y: 0,
      },
      mobile: {
        x: PUZZLE_CONTAINER_DEFAULT_WIDTH.mobile - PUZZLE_PIECE_DEFAULT_WIDTH + PUZZLE_DEFAULT_SPACING * 2,
        y: PUZZLE_DEFAULT_HEIGHT  + PUZZLE_DEFAULT_SPACING * 3,
      }
    },
    container: {
      width: PUZZLE_PIECE_DEFAULT_WIDTH,
      height: PUZZLE_PIECE_DEFAULT_HEIGHT,
    },
    img_default: {
      img: require('../images/puzzle_pieces/puzzle_3.png').default,
      style: {
        top: '-30px',
        left: '-34px',
      }
    },
    img_hover: {
      img: require('../images/puzzle_pieces/puzzle_3_hover.png').default,
      style: {
        top: '-46px',
        left: '-47px',
      }
    },
  },
  piece_4 : {
    default_position: {
      desktop: {
        x: PUZZLE_CONTAINER_DEFAULT_WIDTH.desktop - PUZZLE_PIECE_DEFAULT_WIDTH + PUZZLE_DEFAULT_SPACING * 2,
        y: PUZZLE_PIECE_DEFAULT_HEIGHT + PUZZLE_DEFAULT_SPACING * 2,
      },
      mobile: {
        x: PUZZLE_CONTAINER_DEFAULT_WIDTH.mobile - PUZZLE_PIECE_DEFAULT_WIDTH + PUZZLE_DEFAULT_SPACING * 2,
        y: PUZZLE_DEFAULT_HEIGHT + PUZZLE_PIECE_DEFAULT_HEIGHT + PUZZLE_DEFAULT_SPACING * 4,
      }
    },
    container: {
      width: PUZZLE_PIECE_DEFAULT_WIDTH,
      height: PUZZLE_PIECE_DEFAULT_HEIGHT,
    },
    img_default: {
      img: require('../images/puzzle_pieces/puzzle_4.png').default,
      style: {
        top: '-30px',
        left: '-35px',
      }
    },
    img_hover: {
      img: require('../images/puzzle_pieces/puzzle_4_hover.png').default,
      style: {
        top: '-47px',
        left: '-48px'
      }
    },
  },
}

export const PUZZLE_SOLTS = {
  width: 200,
  height: 200,
  slot_1: {
    x: 0,
    y: 0
  },
  slot_2: {
    x: 200,
    y: 0
  },
  slot_3: {
    x: 0,
    y: 200
  },
  slot_4: {
    x: 200,
    y: 200
  }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, duration = 300) => {
  let timeout;
  return function(...args) {
    const effect = () => {
      timeout = null;
      return func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(effect, duration);
  };
};
