import React from "react";
import styled from "styled-components";
import homeBG from "../images/bg_home.png";
import pageBG from "../images/bg_page.png";

const StyledWrapper = styled.div`
  margin: 0 auto;
  color: #fff;
  padding: 40px 0 100px 0;
  text-align: center;
  min-height: calc(100vh);
  box-sizing: border-box;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  background-image: url(${(props) =>
    props.pageType === "home" ? homeBG : pageBG});
  ${(props) => props.pageType !== "home" && `
  @media only screen and (min-width: 1024px) {
    background-size: 95%;
  }
  `}
  @media (min-width: 600px) {
    padding: 40px 0 60px 0;

  }
`;

function ContentWrap({ children, pageType }) {
  return <StyledWrapper id="content-wrapper" pageType={pageType}>{children}</StyledWrapper>;
}

export default ContentWrap;
