function QuestionDragBgIcon({active = false}) {
  const color = active ? '#fff' : '#17f4f9';
  const opacity = active ? '1': '.5'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346 197"><g data-name="Layer 2"><path d="M323 3l20 20v151l-20 20H23L3 174V23L23 3h300m1.24-3H21.76l-.88.88-20 20-.88.88v153.48l.88.88 20 20 .88.88h302.48l.88-.88 20-20 .88-.88V21.76l-.88-.88-20-20-.88-.88z" fill={color} opacity={opacity} data-name="Layer 1"/></g></svg>
  );
  
}

export default QuestionDragBgIcon;

