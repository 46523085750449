import React from "react";
import styled from "styled-components";
import { GetState } from "../state/stateManager";
import { goToPage } from "../state/actions";

const Debug = styled.p`
  position: fixed;
  border: solid 2px white;
  background: navy;
  font-size: 1.2rem;
  bottom: 20px;
  left: 0;
  z-index: 999;
  padding: 10px;
  text-align: left;
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
  
  a {
    color: white;
  }
`;

const Debugger = ({ currentQuestion = null }) => {
  const [state, dispatch] = GetState();
  
  const resetHandler = (e) => {
    e.preventDefault();
    dispatch(goToPage(0));
  };
  
  if(process.env.REACT_APP_WATCH && currentQuestion) {
    return (
      <Debug id="debugger">
        Speed: {state.score.speed}
        <br />
        Firepower: {state.score.firepower}
        <br />
        Bravery: {state.score.bravery}
        <br />
        Cunning: {state.score.cunning}
        <br />
        {currentQuestion && currentQuestion.interaction && (
          <>
          Interaction: {currentQuestion.interaction}
          </>
        )}
        <br />
        <a className="nextPage" href="/" onClick={resetHandler}>
          RESET
        </a>
      </Debug>
    )
  }
  
  return null;
}

export default Debugger;
