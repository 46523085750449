import { StateProvider } from "./state/stateManager";
import reducer from "./state/reducer";
import Routes from "./routes";

function App() {
  const initialState = {
    currentPage: 0,
    score: {
      speed: 0,
      firepower: 0,
      bravery: 0,
      cunning: 0,
      userName: "",
    },
    sitemap: {},
    chosenResult: "",
  };

  const localState = JSON.parse(sessionStorage.getItem("httydState"));
  return (
    <StateProvider reducer={reducer} initialState={localState || initialState}>
      <Routes />
    </StateProvider>
  );
}

export default App;
