import React from "react";
import styled from "styled-components";
import { FOOTER } from '../constants/copy';
import { currentLocale } from '../constants/app.js';

const StyledFooter = styled.footer`
  padding: 10px 0;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #7e7e7e;
`;

const FooterLink = styled.a`
  margin: 0px 20px;
  text-decoration: none;
  color: #ec2f26;

  &:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    display: block;
    margin-bottom: 5px;
  }
`;

const FooterCopy = styled.span`
  margin: 0px 20px;
  
  @media (max-width: 768px) {
    display: block;
    margin: 0px 20px 10px;
    font-size: 12px;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <span>
        <FooterCopy>
          {FOOTER.copyright_text}{" "}
        </FooterCopy>
        {
          currentLocale !== 'de' && (
            <>
              <FooterLink href={FOOTER.privacy_policy_link} target='_blank'>{FOOTER.privacy_policy_text}</FooterLink>{" "}
              <FooterLink href={FOOTER.terms_and_conditions_link} target='_blank'>{FOOTER.terms_and_conditions_text}</FooterLink>{" "}
            </>
          )
        }
      </span>
    </StyledFooter>
  );
};

export default Footer;
