import React, { useState } from "react";
import styled, { css } from "styled-components";
import NextButton from "../components/NextButton";
import QuestionPickerBgIconActive from '../components/svg/QuestionPickerBgIconActive';
import QuestionPickerBgIconNonActive from '../components/svg/QuestionPickerBgIconNonActive';
import QuestionPickerListBgIcon from '../components/svg/QuestionPickerListBgIcon';

import { CHALLENGE_TYPES } from "../constants/copy";

// import leftPicker from "../images/choice_bg_left.png";
// import rightPicker from "../images/choice_bg_right.png";

import activeOverlay from "../images/active-overlay.png";
import picker_list_bg from '../images/picker_list_bg.png';


// images

const ChoicesList = styled.ul`
  list-style: none;
  margin-top: -50px;
  margin-bottom: -40px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  width: 550px;
  position: relative;
  left: 50%;
  transform: translateX(-50%) scale(0.6);
  
  @media only screen and (min-width: 600px) {
    transform:  scale(1);
    left: auto;
    margin: 50px auto;
    
  }
`;

const ChoiceList = styled.li`
  width: 100%;
  position: relative;
  height: 67px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    filter: drop-shadow(0px 0px 6px rgba(59,188,255,1));
  }
  
  p {
    font-size: 1.4rem;
    position: relative;
  }
  
  img.overlay {
    width: 123%;
    position: absolute;
    right: -64px;
    top: -64px;
  }
  
  ${(props) =>
    props.selected === "selected" &&
    css`
      p {
        color: black;
      }
    `}
`;

const Choices = styled.div`
  list-style: none;
  margin-top: -105px;
  margin-bottom: -85px;
  margin-right: auto;
  margin-left: auto;
  
  width: 620px;
  position: relative;
  
  left: 50%;
  transform: translateX(-50%) scale(0.5);
  
  @media only screen and (min-width: 500px) {
    margin-top: -60px;
    margin-bottom: -30px;
    
    transform: translateX(-50%) scale(0.7);
  }
  
  @media only screen and (min-width: 620px) {
    transform:  scale(1);
    left: auto;
    margin: 0 auto;
    padding: 20px;
  }
`;

const Choice = styled.div`
  display: inline-block;
  height: 205px;
  vertical-align: top;
  width: 300px;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  margin: 5px;
  box-sizing: border-box;

  background-size: 140% auto;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  
  
  
  img.answer_img {
    position: absolute;
    width: calc(100% - 14px);
    left: 13px;
  }
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    filter: drop-shadow(0px 0px 6px rgba(59,188,255,1));
    
  }
  
  &:nth-child(1n) {
    padding-left: 13px;
    padding-right: 3px;
    svg {
      transform: rotate(180deg);
    }
    
    
  }
  &:nth-child(2n) {
    padding-right: 13px;
    padding-left: 3px;
    svg {
      transform: rotate(0deg);
    }
    img.answer_img {
      left: 3px;
    }
  }
  ${(props) =>
    props.selected === "selected" &&
    css`
      img {
        opacity: 1;
      }
      
      p {
        color: black;
      }
      
      &:nth-child(1n) {
        img.overlay {
          opacity: 1;
          width: 131%;
          position: absolute;
          right: -52px;
          top: -52px;
        }
      }
      &:nth-child(2n) {
        img.overlay {
          opacity: 1;
          width: 131%;
          position: absolute;
          left: -52px;
          top: -52px;
        }
      }
    `}
  p {
    font-size: 1.4rem;
    position: absolute;
    width: calc(100% - 16px);
    bottom: 10px;
  }
`;

function MultipleChoice({ interaction, answers }) {
  const [answerTrait, setAnswerTrait] = useState(0);
  

  const handleClick = (trait) => {
    setAnswerTrait(trait);
  };

  let scoreObj = { [answerTrait]: 30 };

  if(interaction === CHALLENGE_TYPES.PICKER_LIST) {
    return (
      <>
        <ChoicesList>
          {answers.map((answer, index) => (
            <ChoiceList
              selected={answerTrait === answer.trait ? "selected" : ""}
              onClick={() => handleClick(answer.trait)}
              key={index}
            >
            <QuestionPickerListBgIcon active={answerTrait === answer.trait} />
              {answerTrait === answer.trait && <img className="overlay" alt="overlay" src={picker_list_bg} />}
              <p>{answer.content}</p>
            </ChoiceList>
          ))}
        </ChoicesList>

        <NextButton active={answerTrait ? true : false} scoring={scoreObj} />
      </>
    );
  }
  
  return (
    <>
      <Choices>
        {answers.map((answer, index) => (
          <Choice
            selected={answerTrait === answer.trait ? "selected" : ""}
            key={index}
            onClick={() => handleClick(answer.trait)}
          >
          {answerTrait === answer.trait ? <QuestionPickerBgIconActive index={`${index}-active`}/> : <QuestionPickerBgIconNonActive index={`${index}-nonactive`}/>}
            {answerTrait === answer.trait && <img className="overlay" alt="overalay" src={activeOverlay} />}
            {answer.image && <img className={'answer_img'} alt="answer" src={require(`../images/${answer.image}`).default}/>}
            <p>{answer.content}</p>
          </Choice>
        ))}
      </Choices>

      <NextButton active={answerTrait ? true : false} scoring={scoreObj} />
    </>
  );
}

export default MultipleChoice;
