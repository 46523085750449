import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import {YOUTUBE_LINKS, VIDEO_LINKS} from "../constants/copy";
import { playerType } from '../constants/app';

import {
  FaArrowCircleLeft,
  FaCloudDownloadAlt,
  FaPlayCircle,
  FaRedo,
} from "react-icons/fa";

import ContentWrap from "../layout/ContentWrap";
import resultParser from "../helpers/resultParser";
import { goToPage, setChoice } from "../state/actions";
import { GetState } from "../state/stateManager";
import { CLOSING, BUTTONS, SHAREABLE } from "../constants/copy";
import LogoArea from "../components/LogoArea";
import QuestionTitle from "../components/QuestionTitle";
import NextButton from "../components/NextButton";
import Button from "../components/Button";
import IntroDescription from "../components/IntroDescription";
import CoinFlip from "../components/CoinFlip";

import { setDownloadImage } from "../state/actions";
import { generateSharableImage } from '../helpers/generateSharableImage';

const videoUrls = {
  boulder: YOUTUBE_LINKS.boulder,
  mystery: YOUTUBE_LINKS.mystery,
  sharp: YOUTUBE_LINKS.sharp,
  stoker: YOUTUBE_LINKS.stoker,
  strike: YOUTUBE_LINKS.strike,
  tidal: YOUTUBE_LINKS.tidal,
  tracker: YOUTUBE_LINKS.tracker,
};

const staticVideoUrls = {
  boulder: VIDEO_LINKS.boulder,
  mystery: VIDEO_LINKS.mystery,
  sharp: VIDEO_LINKS.sharp,
  stoker: VIDEO_LINKS.stoker,
  strike: VIDEO_LINKS.strike,
  tidal: VIDEO_LINKS.tidal,
  tracker: VIDEO_LINKS.tracker,
};

const Player = styled(ReactPlayer)`
  margin: 0 auto;
  max-width: 657px;
  max-height: 370px;
  width: 100vw !important;
  height: 90vh !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 7px 0px;
`;

const StaticPlayer = styled.div`
  margin: 0 auto;
  max-width: 657px;
  max-height: 370px;
  width: 100vw !important;
  height: 90vh !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 7px 0px;
  background-color: #000;
  
  video {
    width: 100%;
    height: 100%;
  }
`;

const ButtonLine = styled.div`
  display: flex;
  justify-content: center;
  
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  
  .results-button {
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 22px;
    
    svg {
      height: 25px;
      width: 25px;
      margin: 0 5px;
    }
  }
`;

function ScorePage() {
  const [state, dispatch] = GetState();
  const [swiped, setSwiped] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const ctaRef = useRef(null);
  const videoRef = useRef(null);
  

  const revealHandler = (e) => {
    setSwiped(true);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    dispatch(goToPage(0));
  };

  const showVideoHandler = (e) => {
    e.preventDefault();
    setShowVideo(true);
  };

  const showClassHandler = (e) => {
    e.preventDefault();
    setShowVideo(false);
  };

  const handleDownloadImageCallback = (data) => {
    dispatch(setDownloadImage(data));
  };

  useEffect(() => {
    generateSharableImage(state.score.userName, state.chosenResult, (data) => {
      handleDownloadImageCallback(data);
    })
    dispatch(setChoice(resultParser(state.score)));
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if(swiped && !showVideo) {
      setTimeout(() => {
        window.scrollTo(0, ctaRef.current.offsetTop);
      }, 1500);
    }
    
    if(showVideo) {
      window.scrollTo(0, videoRef.current.offsetTop);
    }
  }, [swiped, showVideo]);

  const PageAnimation = ({ children }) => {
    // eslint-disable-next-line
    const [state, dispatch] = GetState();
    return (
      <motion.div
        key={state.currentPage}
        exit="out"
        animate="in"
        initial="out"
        variants={resultsTransition}
      >
        {children}
      </motion.div>
    );
  };

  const resultsTransition = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  
  return (
    <ContentWrap>
      <LogoArea />
      <QuestionTitle>{CLOSING[2].title}</QuestionTitle>
      {!swiped ? (
        <PageAnimation>
          <IntroDescription
            title={CLOSING[2].question}
            description={CLOSING[2].instruction}
          />
          <CoinFlip choice={state.chosenResult} onFinish={revealHandler} />
        </PageAnimation>
      ) : (
        <PageAnimation>
          <IntroDescription
            title={state.score.userName}
            description={CLOSING[2].ride}
          />
          {showVideo ? (
            <PageAnimation>
              <span ref={videoRef}>
                {playerType === 'native' ? (
                  <StaticPlayer>
                    <video playsInline autoPlay controls>
                      <source src={staticVideoUrls[state.chosenResult]} type="video/mp4" />
                    </video>
                  </StaticPlayer>
                ) : (<Player
                  url={videoUrls[state.chosenResult]}
                  config={{
                    youtube: {
                      playerVars: {
                        autoplay: 1,
                        showinfo: 0,
                        controls: true,
                        rel: 0
                      },
                      embedOptions: {
                        modestbranding: 1,
                        rel: 0,
                      },
                    },
                  }}
                />)}
              </span>
            </PageAnimation>
          ) : (
            <PageAnimation>
              <CoinFlip flipped="true" choice={state.chosenResult} />
            </PageAnimation>
          )}
          <NextButton timeLeft={SHAREABLE[state.chosenResult]} active={false} />

          <ButtonLine id="cta-buttons" ref={ctaRef}>
            {!showVideo && (
              <Button>
                <a href={state.downloadImage} download={'your_dragon_class'} style={{color: 'white', textDecoration: 'none'}}>
                  <div className="results-button"><FaCloudDownloadAlt /> <span>{BUTTONS.button_download}</span></div>
                </a>
              </Button>
            )}
            {!showVideo && (
              <Button clickHandler={showVideoHandler}>
                <div className="results-button"><FaPlayCircle /> <span>{BUTTONS.button_watch_video}</span></div>
              </Button>
            )}
            {showVideo && (
              <Button clickHandler={showClassHandler}>
                <div className="results-button"><FaArrowCircleLeft />
                <span>{BUTTONS.button_view_class}</span></div>
              </Button>
            )}
            <Button clickHandler={resetHandler}>
              <div className="results-button"><span>{BUTTONS.button_retake_test}</span> <FaRedo /></div>
            </Button>
          </ButtonLine>
        </PageAnimation>
      )}
    </ContentWrap>
  );
}

export default ScorePage;
