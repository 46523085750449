/* eslint-disable */
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {
PUZZLE_SOLTS, PUZZLE_PIECES_POSITIONS} from "../constants/puzzleConstants";

import Draggable from 'react-draggable';

const ContainerDiv = styled.div`
  position: absolute;
  cursor: move;
  ${({dragging}) =>
    dragging &&
    `
    z-index: 999
    `}
`;

const ImgPiece = styled.div`
  .img {
    position: absolute;
    display: block;
    
    ${({dragging}) =>
      dragging &&
      `
      display: none;
      `}
  }
    
  .img-hover {
    position: absolute;
    display: none;
    
    ${({dragging}) =>
      dragging &&
      `
      display: block;
      `}
  }
`;

const PuzzlePiece = ({index, puzzleRef, parentRef, scale, viewType, disableMove, callback, dragCallback, defaultPosition}) => {
  const [defaultPiecePosition, updateDefaultPiecePosition] = useState(defaultPosition);
  const [slotAssigned, updateSlotAssigned] = useState(null);
  const [isDragging, updateIsDragging] = useState(false);
  
  const onStartDrag = (id) => {
    updateIsDragging(true);
  };

  const onControlledDragStop = (e, position) => {
    updateIsDragging(false);
    
    const { x, y, node } = position;
    const currentX = x + node.clientWidth / 2;
    const currentY = y + node.clientHeight / 2;
    const puzzlePlacement = puzzleRef.current.getBoundingClientRect();
    const parentContainer = parentRef.current.getBoundingClientRect();
    const relativeTop = (puzzlePlacement.top / scale) - (parentContainer.top / scale);
    const relativeLeft = (puzzlePlacement.left / scale) - (parentContainer.left / scale);
    
    // In slot 1?
    if(currentX > relativeLeft + PUZZLE_SOLTS.slot_1.x && currentX < relativeLeft + PUZZLE_SOLTS.slot_1.x + PUZZLE_SOLTS.width && currentY > relativeTop + PUZZLE_SOLTS.slot_1.y && currentY < relativeTop + PUZZLE_SOLTS.slot_1.y + PUZZLE_SOLTS.height) {
      callback(index, 'slot_1', {x: relativeLeft + PUZZLE_SOLTS.slot_1.x, y: relativeTop + PUZZLE_SOLTS.slot_1.y}, slotAssigned);
      
      updateSlotAssigned('slot_1');
    }
    // In slot 2?
    if(currentX > relativeLeft + PUZZLE_SOLTS.slot_2.x && currentX < relativeLeft + PUZZLE_SOLTS.slot_2.x + PUZZLE_SOLTS.width && currentY > relativeTop + PUZZLE_SOLTS.slot_2.y && currentY < relativeTop + PUZZLE_SOLTS.slot_2.y + PUZZLE_SOLTS.height) {
      callback(index, 'slot_2', {x: relativeLeft + PUZZLE_SOLTS.slot_2.x, y: relativeTop + PUZZLE_SOLTS.slot_2.y}, slotAssigned);
      
      updateSlotAssigned('slot_2');
      
    }
    // In slot 3?
    if(currentX > relativeLeft + PUZZLE_SOLTS.slot_3.x && currentX < relativeLeft + PUZZLE_SOLTS.slot_3.x + PUZZLE_SOLTS.width && currentY > relativeTop + PUZZLE_SOLTS.slot_3.y && currentY < relativeTop + PUZZLE_SOLTS.slot_3.y + PUZZLE_SOLTS.height) {
      callback(index, 'slot_3', {x: relativeLeft + PUZZLE_SOLTS.slot_3.x, y: relativeTop + PUZZLE_SOLTS.slot_3.y}, slotAssigned);
      
      updateSlotAssigned('slot_3');
      
    }
    // In slot 4?
    if(currentX > relativeLeft + PUZZLE_SOLTS.slot_4.x && currentX < relativeLeft + PUZZLE_SOLTS.slot_4.x + PUZZLE_SOLTS.width && currentY > relativeTop + PUZZLE_SOLTS.slot_4.y && currentY < relativeTop + PUZZLE_SOLTS.slot_4.y + PUZZLE_SOLTS.height) {
      callback(index, 'slot_4', {x: relativeLeft + PUZZLE_SOLTS.slot_4.x, y: relativeTop + PUZZLE_SOLTS.slot_4.y}, slotAssigned);
      
      updateSlotAssigned('slot_4');
    }
  };
  
  useEffect(() => {
    if(slotAssigned === null) {
      updateDefaultPiecePosition(PUZZLE_PIECES_POSITIONS[index].default_position[viewType]);
    }
  }, [viewType]);
  
  
  useEffect(() => {
    updateDefaultPiecePosition(defaultPosition);
  }, [defaultPosition]);
  
  return (
    <Draggable position={defaultPiecePosition}  onStart={onStartDrag} onStop={onControlledDragStop} bounds="parent" scale={scale} disabled={disableMove}>
      <ContainerDiv style={PUZZLE_PIECES_POSITIONS[index].container} dragging={isDragging}>
        <ImgPiece dragging={isDragging} isPlaced={(slotAssigned !== null)}>
          <img alt="puzzle piece" dataIndex={index} src={PUZZLE_PIECES_POSITIONS[index].img_default.img} className="img" style={PUZZLE_PIECES_POSITIONS[index].img_default.style} onDragStart={(e) => {
              e.preventDefault();
              return false;
            }}/>
          <img alt="puzzle piece hover" src={PUZZLE_PIECES_POSITIONS[index].img_hover.img} className="img-hover" style={PUZZLE_PIECES_POSITIONS[index].img_hover.style} onDragStart={(e) => {
              e.preventDefault();
              return false;
            }}/>
        </ImgPiece>
      </ContainerDiv>
    </Draggable>
  );
};

export default PuzzlePiece;
