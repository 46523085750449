import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  margin-bottom: 30px;
  // min-height: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    position: absolute;
    bottom: 0;
  }
  
  @media (min-width: 750px) {
    padding: 10px 0;
    margin-bottom: 0px;
  }
`;

function GameFrame({ children }) {
  return <StyledWrapper>{children}</StyledWrapper>;
}

export default GameFrame;
