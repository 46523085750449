const resultParser = (scores) => {
  const results = [
    ["bravery", scores.bravery],
    ["cunning", scores.cunning],
    ["firepower", scores.firepower],
    ["speed", scores.speed],
  ];

  // sort by score, grab first two keys
  results.sort(function (a, b) {
    return b[1] - a[1];
  });
  let chosenResult = "";
  const ranking = [results[0][0], results[1][0]];
  if (ranking[0] === "speed") {
    //pick between strike, sharp and mystery
    if (ranking[1] === "firepower") {
      chosenResult = "strike";
    } else if (ranking[1] === "bravery") {
      chosenResult = "sharp";
    } else if (ranking[1] === "cunning") {
      chosenResult = "mystery";
    }
  } else if (ranking[0] === "firepower") {
    //pick between strike, stoker and tidal
    if (ranking[1] === "speed") {
      chosenResult = "strike";
    } else if (ranking[1] === "bravery") {
      chosenResult = "stoker";
    } else if (ranking[1] === "cunning") {
      chosenResult = "tidal";
    }
  } else if (ranking[0] === "bravery") {
    //pick between sharp, boulder and tracker
    if (ranking[1] === "speed") {
      chosenResult = "sharp";
    } else if (ranking[1] === "firepower") {
      chosenResult = "boulder";
    } else if (ranking[1] === "cunning") {
      chosenResult = "tracker";
    }
  } else if (ranking[0] === "cunning") {
    //pick between mystery, tidal and tracker
    if (ranking[1] === "speed") {
      chosenResult = "mystery";
    } else if (ranking[1] === "firepower") {
      chosenResult = "tidal";
    } else if (ranking[1] === "bravery") {
      chosenResult = "tracker";
    }
  }
  return chosenResult;
};

export default resultParser;
