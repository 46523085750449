// Action types

export const PAGE_CHANGED = "pageChange";
export const SCORE_UPDATED = "updateScore";
export const SCORE_RESET = "resetScore";
export const SITEMAP_GENERATED = "setSiteMap";
export const DRAGON_CHOSEN = "setChosenDragon";
export const SET_DOWNLOAD_IMAGE = "setDownloadImage";

// Action creators
export const goToPage = (targetPage) => {
  return {
    type: PAGE_CHANGED,
    payload: targetPage,
  };
};

export const nextPage = (currentPage) => {
  return {
    type: PAGE_CHANGED,
    payload: currentPage + 1,
  };
};

export const resetScores = () => {
  return {
    type: SCORE_RESET,
  };
};

export const setDownloadImage = (data) => {
  return {
    type: SET_DOWNLOAD_IMAGE,
    payload: data
  };
};

export const updateScore = (trait, delta, prev) => {
  return {
    type: SCORE_UPDATED,
    payload: { [trait]: prev[trait] + delta },
  };
};

export const setSiteMap = (generatedSiteMap) => {
  return {
    type: SITEMAP_GENERATED,
    payload: generatedSiteMap,
  };
};

export const setChoice = (choice) => {
  return {
    type: DRAGON_CHOSEN,
    payload: choice,
  };
};
