import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { BUTTONS } from '../constants/copy';


import NextButton from "../components/NextButton";
import GameFrame from "../layout/GameFrame";

import flame1 from "../images/flame1.svg";
import flame2 from "../images/flame2.svg";
import flame3 from "../images/flame3.svg";

// seperate background images and size styles so SC doesn't redraw every time
// marry button to the bottom with a new wrap

const MashVisual = styled.div`
  display: block;
  margin: 0px auto;
  padding: 0;
  background: ${(props) => props.background};
  min-height: 30px;
  min-width: 50px;
  height: ${(props) => 30 + props.size * 7}px;
  width: ${(props) => 50 + props.size * 7}px;

  img {
    width: 100%;
    height: 100%;
  }
  
  @media (min-width: 750px) {
    height: ${(props) => 30 + props.size * 15}px;
    width: ${(props) => 50 + props.size * 15}px;
  }
`;

const SafeArea = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 750px) {
    height: 300px;
  }
`;

function ButtonMash() {
  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState(5);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const countRef = useRef(null);
  const [score, setScore] = useState(10);
  const [mashImage, setMashImage] = useState(flame1);

  const handleTimerStart = () => {
    if (started) return;
    setStarted(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 0.1); //counts down timer
      setClickCount((clickCount) => {
        if(Math.floor(clickCount - 0.3) < 0) {
          return 0;
        }
        return clickCount - 0.3;
      }); //reduces mash target over time
    }, 100);
  };

  useEffect(() => {
    if (timer <= 0) {
      handleTimerEnd();
    }
  }, [timer]);

  const handleTimerEnd = () => {
    clearInterval(countRef.current);
    setFinished(true);
  };

  useEffect(() => {
    if (clickCount >= 10) {
      setScore(30);
      setMashImage(flame3);
    } else if (clickCount >= 7) {
      setScore(25);
      setMashImage(flame3);
    } else if (clickCount >= 3) {
      setScore(20);
      setMashImage(flame2);
    } else if (clickCount >= 1) {
      setScore(15);
      setMashImage(flame2);
    } else {
      setScore(10);
      setMashImage(flame1);
    }
  }, [clickCount]);

  const handleMash = (e) => {
    handleTimerStart();
    setClickCount((clickCount) => clickCount + 1);
  };
  const scoreObj = { firepower: score };
  return (
    <>
      <GameFrame>
        <SafeArea>
          <MashVisual size={clickCount}>
            <img src={mashImage} alt="" />
          </MashVisual>
        </SafeArea>
        {!finished && (
          <Button active={started} clickHandler={handleMash}>
            {!started ? BUTTONS.button_charge : BUTTONS.button_click}
          </Button>
        )}
      </GameFrame>
      <NextButton
        timeLeft={parseFloat(timer).toFixed(2)}
        active={finished}
        scoring={scoreObj}
      />
    </>
  );
}

export default ButtonMash;
