import React from "react";
import styled from "styled-components";

import { PREAMBLE, LOGO_PAGE } from "../constants/copy";

const StyledLogoArea = styled.div`
  img {
    max-width: 380px;
    width: 100%;
    margin: -50px auto 0;
  }

  h4 {
    font-size: 25px;
    font-weight: 400;
    margin: -10px auto 0;
  }
`;

const LogoArea = () => {
  return (
    <StyledLogoArea>
      <img
        src={LOGO_PAGE}
        className="dragonsLogoSmall"
        alt="Dreamworks Dragons"
      />
      <h4 className="siteTitle">{PREAMBLE.title}</h4>
    </StyledLogoArea>
  );
};

export default LogoArea;
