function QuestionPickerListBgIcon({active = false}) {
  const color = active ? '#fff' : '#17f4f9';
  const opacity = active ? '1': '.5'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.49 86"><g data-name="Layer 2"><path d="M664.24 3l40 40-40 40h-620l-40-40 40-40h620m1.25-3H43l-.88.88L7.39 35.61l-5.27 5.27L0 43l2.12 2.12 5.27 5.27 34.73 34.73.88.88h622.49l.87-.88 34.74-34.73 5.26-5.27 2.13-2.12-2.13-2.12-5.26-5.27L666.36.88l-.87-.88z" fill={color} opacity={opacity} data-name="Layer 1"/></g></svg>
  );
  
}

export default QuestionPickerListBgIcon;

