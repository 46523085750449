import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CHALLENGE_TYPES, QUESTION_TITLE } from "../constants/copy";
import { GetState } from "../state/stateManager";
import Footer from "../layout/Footer";
import ContentWrap from "../layout/ContentWrap";
import Debugger from "../components/Debugger";

import ProgressIndicator from "../components/ProgressIndicator";
import QuestionReorder from "../challenges/QuestionReorder";
import QuestionSlider from "../challenges/QuestionSlider";
import MultipleChoice from "../challenges/QuestionPicker";
import DndUpdatedPuzzle from "../challenges/PuzzleUpdatedChallenge";
import ButtonMash from "../challenges/MashChallenge";
import ChickenChallenge from "../challenges/ChickenChallenge";
import FlyingGame from "../challenges/FlyingGame";
import FireballGame from "../challenges/FireballGame";
import MazeChallenge from "../challenges/MazeChallenge";

import NextButton from "../components/NextButton";
import LogoArea from "../components/LogoArea";
import QuestionTitle from "../components/QuestionTitle";
import IntroDescription from "../components/IntroDescription";


import inputbg from "../images/input_box.svg";


const StyledInput = styled.input`
  padding: 20px 30px;
  margin: 10px auto 50px;
  font-size: 1.2rem;
  color: #fff;
  background-image: url(${inputbg});
  background-size: 100% 100%;
  border: none;
  background-color: transparent;
  outline: none;
`;

const QuizPage = ({ type }) => {
  // eslint-disable-next-line
  const [state, dispatch] = GetState();
  const [userName, setUserName] = useState();
  const [currentChallenge, setCurrentChallenge] = useState();
  const currentQuestion = state.sitemap[state.currentPage - 1];
  const lastQuestion = state.sitemap[state.sitemap.length - 1];

  const handleChange = (e) => {
    setUserName(e.target.value);
    // then dispatch it on next
  };
  
  useEffect(() => {
    if(currentQuestion) {
      switch (currentQuestion.interaction) {
        // Games
        case CHALLENGE_TYPES.FIREBALL:
          setCurrentChallenge(<FireballGame />);
          break;
        case CHALLENGE_TYPES.FLYING:
          setCurrentChallenge(<FlyingGame />);
          break;
        // Challenges
        case CHALLENGE_TYPES.MAZE:
          setCurrentChallenge(<MazeChallenge />);
          break;
        case CHALLENGE_TYPES.PUZZLE:
          setCurrentChallenge(<DndUpdatedPuzzle />);
          break;
        case CHALLENGE_TYPES.BUTTON_MASH:
          setCurrentChallenge(<ButtonMash />);
          break;
        case CHALLENGE_TYPES.CHICKEN:
          setCurrentChallenge(<ChickenChallenge />);
          break;
        //Question types
        case CHALLENGE_TYPES.SLIDER:
          setCurrentChallenge(
            <QuestionSlider answers={currentQuestion.answers} />
          );
          break;
        case CHALLENGE_TYPES.PICKER_IMAGE:
        case CHALLENGE_TYPES.PICKER_LIST:
          setCurrentChallenge(
            <MultipleChoice interaction={currentQuestion.interaction} answers={currentQuestion.answers} />
          );
          break;
        case CHALLENGE_TYPES.REORDER:
          setCurrentChallenge(
            <QuestionReorder answers={currentQuestion.answers} />
          );
          break;
        default:
          setCurrentChallenge(<h2>Current interaction missing</h2>);

          break;
      }
    }
    
    // console.log(
    //   "Quiz page",
    //   currentQuestion,
    //   // state.currentPage,
    //   // currentChallenge,
    //   // currentQuestion.preferences
    //   state.sitemap,
    //   state.currentPage
    // );
    
    // eslint-disable-next-line
  }, [currentQuestion]);
  
  return (
    <ContentWrap>
      <header>
        <LogoArea />
      </header>
      <main id="main">
        {type === "lastQuestion" ? (
          <>
            <div className="titleArea">
              <QuestionTitle>{lastQuestion.title}</QuestionTitle>
              <IntroDescription
                title={lastQuestion.question}
                description={lastQuestion.instruction}
              />
            </div>
            <div className="interactionArea">
              <StyledInput
                placeholder={lastQuestion.placeholder}
                value={userName}
                onChange={handleChange}
              />
              <NextButton
                active={userName?.length > 1}
                scoring={{ userName: userName }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="titleArea">
              <QuestionTitle>{QUESTION_TITLE} {state.currentPage}</QuestionTitle>

              <IntroDescription
                title={currentQuestion && currentQuestion.question ? currentQuestion.question : ''}
                description={currentQuestion && currentQuestion.instruction ? currentQuestion.instruction : ''}
              />
            </div>
            <div className="interactionArea" style={{position: 'relative'}}>{currentChallenge}</div>
          </>
        )}

        <ProgressIndicator />
      </main>
      <Footer />
      <Debugger currentQuestion={currentQuestion} />
    </ContentWrap>
  );
};

export default QuizPage;
