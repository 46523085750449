import { SHAREABLE } from './copy';
import { currentLocale } from './app.js';

// image constants
export const BG_DEFAULT_WIDTH = 800;
export const BG_DEFAULT_HEIGHT = 961;

const logos = {
  de: require('../assets/locale/de/sharable_logo.png').default,
  en: require('../assets/locale/en/sharable_logo.png').default,
  es: require('../assets/locale/es/sharable_logo.png').default,
  fr: require('../assets/locale/fr/sharable_logo.png').default,
  it: require('../assets/locale/it/sharable_logo.png').default,
  nl: require('../assets/locale/nl/sharable_logo.png').default,
};
export const LOGO = logos[currentLocale];

export const BG_URLS = {
  boulder: require("../images/sharable_class_backgrounds/sharable_boulder_bg.jpg").default,
  mystery: require("../images/sharable_class_backgrounds/sharable_mystery_bg.jpg").default,
  sharp: require("../images/sharable_class_backgrounds/sharable_sharp_bg.jpg").default,
  stroker: require("../images/sharable_class_backgrounds/sharable_stroker_bg.jpg").default,
  tidal: require("../images/sharable_class_backgrounds/sharable_tidal_bg.jpg").default,
  tracker: require("../images/sharable_class_backgrounds/sharable_tracker_bg.jpg").default,
  strike: require("../images/sharable_class_backgrounds/sharable_strike_bg.jpg").default,
}

// locale text constants
export const SHAREABLE_COPY = {
  your_dragon_class_text: SHAREABLE.your_dragon_class_text,
  boulder: SHAREABLE.boulder,
  mystery: SHAREABLE.mystery,
  sharp: SHAREABLE.sharp,
  stroker: SHAREABLE.stroker,
  tidal: SHAREABLE.tidal,
  tracker: SHAREABLE.tracker,
  strike: SHAREABLE.strike
}