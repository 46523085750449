/* eslint-disable */
import {
  CLINCHER_QUESTIONS,
  QUESTION_TYPES,
  GENERIC_QUESTIONS,
  CLOSING,
} from "../constants/copy";

// quiz contents:
// 1 game (out of 2)
// 2 trait specific (out of 4 but fixed bc opposite to the game)
// 2 generic questions (out of six, 3 formats, w no repeats)
// 1 (optional?) clincher (out of 2, 3 formats(?))

// gets number between 0 and "max"
const getRandomInt = (max) => {
  max = Math.floor(max + 1);
  return Math.floor(Math.random() * max);
};

const siteMapGenerator = () => {
  const mutableSource = {
    challenges: [...QUESTION_TYPES.challenges],
    games: [...QUESTION_TYPES.games],
    questionTypes: [...QUESTION_TYPES.questionTypes],
    clinchers: [...CLINCHER_QUESTIONS],
    questionsCopy: [...GENERIC_QUESTIONS],
    closingCopy: [...CLOSING],
  };
  const {
    challenges,
    games,
    questionTypes,
    questionsCopy,
    clinchers,
    closingCopy,
  } = mutableSource;

  const newSiteMap = [];
  const gameSeed = getRandomInt(1); // 0 or 1
  newSiteMap.push(
    // pulls in 1 of the two game options
    games[gameSeed],
    // plus the two challenges that balance it - tightly coupled to copy object order
    challenges[gameSeed],
    challenges[gameSeed + 2]
  );

  // 1st question: randomises copy and type
  const questionCopySeed = getRandomInt(5); // 0 to 5
  const firstQuestion = {
    ...questionsCopy[questionCopySeed],
  };
  
  console.log('firstQuestion',firstQuestion);

  newSiteMap.push(firstQuestion);

  // removes the used elements from the arrays so no repeats
  questionsCopy.splice(questionCopySeed, 1);

  // 2st question
  const questionCopySeed2 = getRandomInt(4); // 0 to 4
  console.log('questionCopySeed2',questionCopySeed2);
  
  const secondQuestion = {
    ...questionsCopy[questionCopySeed2],
  };

  newSiteMap.push(secondQuestion);

  // randomises the order
  newSiteMap.sort((a, b) => 0.5 - Math.random());

  // removes the used elements from the arrays so no repeats

  // clincher question
  // const questionCopySeed3 = getRandomInt(clinchers.length - 1); // 0 to 1 - there are 2 clincher questions
  // const clincher = {
  //   ...clinchers[questionCopySeed3],
  // };
  // newSiteMap.push(clincher);
  
  // Final Question
  const questionCopySeed3 = getRandomInt(4); // 0 to 4
  console.log('questionCopySeed2',questionCopySeed2);
  
  const clincher = {
    ...questionsCopy[questionCopySeed3],
  };

  // Final question: asks for name
  newSiteMap.push(closingCopy[0]);
  
  return newSiteMap;
};

export default siteMapGenerator;
