import { currentLocale } from './app.js';
// This is horrible - depending on how many locales they support
// But I've researched alot and can't find a better solution
// NOTE: should it be a XHR call instead???
const languages = {
  de: require('../assets/locale/de/copy.json'),
  en: require('../assets/locale/en/copy.json'),
  es: require('../assets/locale/es/copy.json'),
  fr: require('../assets/locale/fr/copy.json'),
  it: require('../assets/locale/it/copy.json'),
  nl: require('../assets/locale/nl/copy.json'),
};
const COPY = languages[currentLocale];

const logos_page = {
  de: require('../assets/locale/de/title_page.png').default,
  en: require('../assets/locale/en/title_page.png').default,
  es: require('../assets/locale/es/title_page.png').default,
  fr: require('../assets/locale/fr/title_page.png').default,
  it: require('../assets/locale/it/title_page.png').default,
  nl: require('../assets/locale/nl/title_page.png').default,
};
export const LOGO_PAGE = logos_page[currentLocale];

const logos_home = {
  de: require('../assets/locale/de/title_home.png').default,
  en: require('../assets/locale/en/title_home.png').default,
  es: require('../assets/locale/es/title_home.png').default,
  fr: require('../assets/locale/fr/title_home.png').default,
  it: require('../assets/locale/it/title_home.png').default,
  nl: require('../assets/locale/nl/title_home.png').default,
};
export const LOGO_HOME = logos_home[currentLocale];

export const CHALLENGE_TYPES = {
  REORDER: "reorder",
  PUZZLE: "puzzle",
  BUTTON_MASH: "buttonMash",
  CHICKEN: "chicken",
  SLIDER: "slider",
  PICKER: "multipleChoice",
  MAZE: "maze",
  FIREBALL: "fireball",
  FLYING: "flying",
  PICKER_IMAGE: 'picker_image',
  PICKER_LIST: 'picker_list'
};

export const FULLSCREEN_GESTURE = {
  rotate_screen_1: COPY.mobile_fullscreen.rotate_screen_1,
  rotate_screen_2: COPY.mobile_fullscreen.rotate_screen_2,
  android_fullscreen_gesture: COPY.mobile_fullscreen.android_fullscreen_gesture,
  ios_fullscreen_gesture: COPY.mobile_fullscreen.ios_fullscreen_gesture,
  mobile_close_button: COPY.mobile_fullscreen.mobile_close_button
}

export const MAZE = {
  start: COPY.maze_copy.start,
  finish: COPY.maze_copy.finish,
}

export const SHAREABLE = {
  your_dragon_class_text: COPY.sharable_image.your_dragon_class_text,
  boulder: COPY.sharable_image.class_boulder_name,
  mystery: COPY.sharable_image.class_mystery_name,
  sharp: COPY.sharable_image.class_sharp_name,
  stoker: COPY.sharable_image.class_stoker_name,
  tidal: COPY.sharable_image.class_tidal_name,
  tracker: COPY.sharable_image.class_tracker_name,
  strike: COPY.sharable_image.class_strike_name
}

export const FOOTER = {
  copyright_text: COPY.footer.copyright_text,
  privacy_policy_text: COPY.footer.privacy_policy_text,
  privacy_policy_link: COPY.footer.privacy_policy_link,
  terms_and_conditions_text: COPY.footer.terms_and_conditions_text,
  terms_and_conditions_link: COPY.footer.terms_and_conditions_link
}

export const BUTTONS = {
  button_next: COPY.buttons.button_next_text,
  button_stop: COPY.buttons.button_stop_text,
  button_stopped: COPY.buttons.button_stopped_text,
  button_charge: COPY.buttons.button_charge_text,
  button_click: COPY.buttons.button_click_text,
  button_go: COPY.buttons.button_go_text,
  button_download: COPY.buttons.button_download_text,
  button_watch_video: COPY.buttons.button_watch_video_text,
  button_view_class: COPY.buttons.button_view_class_text,
  button_retake_test: COPY.buttons.button_retake_test_text
}

export const PREAMBLE = {
  logoAlt: COPY.landing_page.logoAlt,
  title: COPY.landing_page.title,
  title1: COPY.landing_page.title1,
  title2: COPY.landing_page.title2,
  cta: COPY.landing_page.cta,
  subtitle: COPY.landing_page.subtitle,
  resultOptions: [
    {
      slug: 'boulder',
      name: COPY.landing_page.class_boulder_name,
      description: COPY.landing_page.class_boulder_description,
    },
    {
      slug: 'mystery',
      name: COPY.landing_page.class_mystery_name,
      description: COPY.landing_page.class_mystery_description,
    },
    {
      slug: 'sharp',
      name: COPY.landing_page.class_sharp_name,
      description: COPY.landing_page.class_sharp_description,
    },
    {
      slug: "stoker",
      name: COPY.landing_page.class_stoker_name,
      description: COPY.landing_page.class_stoker_description,
    },
    {
      slug: "tidal",
      name: COPY.landing_page.class_tidal_name,
      description: COPY.landing_page.class_tidal_description,
    },
    {
      slug: "tracker",
      name: COPY.landing_page.class_tracker_name,
      description: COPY.landing_page.class_tracker_description,
    },
    {
      slug: "strike",
      name: COPY.landing_page.class_strike_name,
      description: COPY.landing_page.class_strike_description,
    },
  ],
};

export const QUESTION_TYPES = {
  games: [
    {
      question: COPY.question_descriptions.fireball_game_title,
      interaction: CHALLENGE_TYPES.FIREBALL,
    },
    {
      question: COPY.question_descriptions.flying_game_title,
      interaction: CHALLENGE_TYPES.FLYING,
    },
  ],
  challenges: [
    {
      question: COPY.question_descriptions.puzzle_challenge_title,
      instruction:COPY.question_descriptions.puzzle_challenge_instruction,
      interaction: CHALLENGE_TYPES.PUZZLE,
    },
    {
      question: COPY.question_descriptions.button_mash_challenge_title,
      instruction: COPY.question_descriptions.button_mash_challenge_instruction,
      interaction: CHALLENGE_TYPES.BUTTON_MASH,
    },
    {
      question: COPY.question_descriptions.stop_as_close_as_you_can_challenge_title,
      instruction: COPY.question_descriptions.stop_as_close_as_you_can_challenge_instruction,
      interaction: CHALLENGE_TYPES.CHICKEN,
    },
    {
      question: COPY.question_descriptions.maze_challenge_title,
      instruction: COPY.question_descriptions.maze_challenge_instruction,
      interaction: CHALLENGE_TYPES.MAZE,
    },
  ],
  questionTypes: [
    {
      instruction: COPY.question_descriptions.reorder_question_instruction,
      interaction: CHALLENGE_TYPES.REORDER,
    },
    {
      instruction: COPY.question_descriptions.slider_question_instruction,
      interaction: CHALLENGE_TYPES.SLIDER,
    },
    {
      instruction: COPY.question_descriptions.multiple_choice_question_instruction,
      interaction: CHALLENGE_TYPES.PICKER,
    },
  ],
};

export const APPENDIX = {
  preferences: [COPY.question_descriptions.reorder_question_best_text, COPY.question_descriptions.reorder_question_better_text, COPY.question_descriptions.reorder_question_worse_text, COPY.question_descriptions.reorder_question_worst_text],
};

export const QUESTION_TITLE = COPY.questions.question_title;

export const GENERIC_QUESTIONS = [
  {
    id: 'question_1',
    question: COPY.questions.question_1.question_title,
    interaction: CHALLENGE_TYPES.REORDER,
    answers: [
      { content: COPY.questions.question_1.answer_1_title, trait: "speed", image: 'question_1/dragon_racing.png' },
      { content: COPY.questions.question_1.answer_2_title, trait: "firepower", image: 'question_1/dragon_fighting.png' },
      { content: COPY.questions.question_1.answer_3_title, trait: "bravery", image: 'question_1/dragon_training.png' },
      { content: COPY.questions.question_1.answer_4_title, trait: "cunning", image: 'question_1/studying_the_books_of_dragons.png' },
    ],
  },
  {
    id: 'question_2',
    question: COPY.questions.question_2.question_title,
    interaction: CHALLENGE_TYPES.SLIDER,
    answers: [
      { content: COPY.questions.question_2.answer_1_title, trait: "speed", image: 'question_2/saddle.png' },
      { content: COPY.questions.question_2.answer_2_title, trait: "firepower", image: 'question_2/inferno.png' },
      { content: COPY.questions.question_2.answer_3_title, trait: "bravery", image: 'question_2/dragon_eye.png' },
      { content: COPY.questions.question_2.answer_4_title, trait: "cunning", image: 'question_2/book_of_dragons.png' },
    ],
  },
  {
    id: 'question_3',
    question: COPY.questions.question_3.question_title,
    interaction: CHALLENGE_TYPES.PICKER_IMAGE,
    answers: [
      { content: COPY.questions.question_3.answer_1_title, trait: "speed", image: 'question_3/i_cant_choose.png' },
      { content: COPY.questions.question_3.answer_2_title, trait: "firepower", image: 'question_3/dragon_island.png' },
      { content: COPY.questions.question_3.answer_3_title, trait: "bravery", image: 'question_3/hidden_world.png' },
      { content: COPY.questions.question_3.answer_4_title, trait: "cunning", image: 'question_3/barbaric_archipelago.png' },
    ],
  },
  {
    id: 'question_4',
    question: COPY.questions.question_4.question_title,
    interaction: CHALLENGE_TYPES.PICKER_IMAGE,
    answers: [
      { content: COPY.questions.question_4.answer_1_title, trait: "speed", image: 'question_4/blackened_chicken.png' },
      { content: COPY.questions.question_4.answer_2_title, trait: "firepower", image: 'question_4/apples_and_nuts.png' },
      { content: COPY.questions.question_4.answer_3_title, trait: "bravery", image: 'question_4/seven_spice_seafood_surprise.png' },
      { content: COPY.questions.question_4.answer_4_title, trait: "cunning", image: 'question_4/honey.png' },
    ],
  },
  {
    id: 'question_5',
    question: COPY.questions.question_5.question_title,
    interaction: CHALLENGE_TYPES.PICKER_LIST,
    answers: [
      { content: COPY.questions.question_5.answer_1_title, trait: "speed", image: '' },
      { content: COPY.questions.question_5.answer_2_title, trait: "firepower", image: '' },
      { content: COPY.questions.question_5.answer_3_title, trait: "bravery", image: '' },
      { content: COPY.questions.question_5.answer_4_title, trait: "cunning", image: '' },
    ],
  },
  {
    id: 'question_6',
    question: COPY.questions.question_6.question_title,
    interaction: CHALLENGE_TYPES.SLIDER,
    answers: [
      { content: COPY.questions.question_6.answer_1_title, trait: "speed", image: 'question_6/heardsman.png' },
      { content: COPY.questions.question_6.answer_2_title, trait: "firepower", image: 'question_6/blacksmith.png' },
      { content: COPY.questions.question_6.answer_3_title, trait: "bravery", image: 'question_6/chief.png' },
      { content: COPY.questions.question_6.answer_4_title, trait: "cunning", image: 'question_6/doctor.png' },
    ],
  },
  {
    id: 'question_7',
    question: COPY.questions.question_7.question_title,
    interaction: CHALLENGE_TYPES.PICKER_LIST,
    answers: [
      { content: COPY.questions.question_7.answer_1_title, trait: "speed", image: '' },
      { content: COPY.questions.question_7.answer_2_title, trait: "firepower", image: '' },
      { content: COPY.questions.question_7.answer_3_title, trait: "bravery", image: '' },
      { content: COPY.questions.question_7.answer_4_title, trait: "cunning", image: '' },
    ],
  },
  {
    id: 'question_8',
    question: COPY.questions.question_8.question_title,
    interaction: CHALLENGE_TYPES.REORDER,
    answers: [
      { content: COPY.questions.question_8.answer_1_title, trait: "speed",image: 'question_8/black.png' },
      { content: COPY.questions.question_8.answer_2_title, trait: "firepower", image: 'question_8/red.png' },
      { content: COPY.questions.question_8.answer_3_title, trait: "bravery", image: 'question_8/green.png' },
      { content: COPY.questions.question_8.answer_4_title, trait: "cunning", image: 'question_8/blue.png' },
    ],
  },
];

// THERE ARE NO CLINCHER QUESTIONS NOW
export const CLINCHER_QUESTIONS = [
  {
    question: "Who is your favourite dragon rider?",
    answers: [
      { content: "Tuffnut & Ruffnut", trait: "speed" },
      { content: "Snotlout", trait: "firepower" },
      { content: "Astrid", trait: "bravery" },
      { content: "Hiccup", trait: "cunning" },
    ],
  },
  {
    question: "Which is the coolest dragon?",
    answers: [
      { content: "Night Fury", trait: "speed" },
      { content: "Hookfang", trait: "firepower" },
      { content: "Gronckle", trait: "bravery" },
      { content: "Deadly Nadder", trait: "cunning" },
    ],
  },
];


export const CLOSING = [
  {
    title: COPY.enter_name_screen.title,
    question: COPY.enter_name_screen.question,
    instruction: COPY.enter_name_screen.instruction,
    placeholder: COPY.enter_name_screen.placeholder,
    cta: COPY.enter_name_screen.cta,
  },
  {
    title: COPY.score_screen.title,
    cta: COPY.score_screen.cta,
    speed: COPY.score_screen.speed,
    firepower: COPY.score_screen.firepower,
    bravery: COPY.score_screen.bravery,
    cunning: COPY.score_screen.cunning,
  },
  {
    title: COPY.reveal_class_screen.title,
    question: COPY.reveal_class_screen.question,
    instruction: COPY.reveal_class_screen.instruction,
    ride: COPY.reveal_class_screen.ride,
    ctaDownload: COPY.reveal_class_screen.ctaDownload,
    ctaVideo: COPY.reveal_class_screen.ctaVideo,
    ctaRestart: COPY.reveal_class_screen.ctaRestart,
    ctaClass: COPY.reveal_class_screen.ctaClass,
  },
];

export const YOUTUBE_LINKS = {
  boulder: COPY.class_youtube_links.boulder,
  mystery: COPY.class_youtube_links.mystery,
  sharp: COPY.class_youtube_links.sharp,
  stoker: COPY.class_youtube_links.stoker,
  strike: COPY.class_youtube_links.strike,
  tidal: COPY.class_youtube_links.tidal,
  tracker: COPY.class_youtube_links.tracker,
}

export const VIDEO_LINKS = {
  boulder: COPY.class_mp4_links.boulder,
  mystery: COPY.class_mp4_links.mystery,
  sharp: COPY.class_mp4_links.sharp,
  stoker: COPY.class_mp4_links.stoker,
  strike: COPY.class_mp4_links.strike,
  tidal: COPY.class_mp4_links.tidal,
  tracker: COPY.class_mp4_links.tracker,
}