/* eslint-disable */
function getParameterByName(name, defaultValue, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results || !results[2]) return defaultValue;
    
    if(name === 'lang'){
      document.getElementsByTagName("html")[0].lang = decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const allowedLangs = ['de', 'en', 'es', 'fr', 'it', 'nl'];

export const parseLangData = (data) => {
  const paramLangCheck = getParameterByName('lang', false);
  let lang = paramLangCheck ? getParameterByName('lang', 'en') : data.split('-')[0];
  
  if(allowedLangs.indexOf(lang) !== -1) {
    return (lang || 'en').toLowerCase();
  }
  
  return 'en';
};

export const currentLocale = parseLangData(('language' in navigator ) ? navigator.language : 'en');
export const playerType = getParameterByName('player', 'youtube');

// AUDIO LOGIC
const BUTTON_AUDIO = new Audio(require('../audio/button_press.mp3').default);
const CLASS_REVEAL_AUDIO = new Audio(require('../audio/class_reveal.mp3').default);

export const playButtonAudio = () => {
  const playPromise = BUTTON_AUDIO.play();

  if (playPromise !== undefined) {
    playPromise.then(_ => {
      // playPromise.play();
    })
    .catch(error => {
      console.log('user intercation needed first', error);
    });
  }
}

export const playClassRevealAudio = () => {
  const playPromise = CLASS_REVEAL_AUDIO.play();

  if (playPromise !== undefined) {
    playPromise.then(_ => {
      // playPromise.play();
    })
    .catch(error => {
      console.log('user intercation needed first', error);
    });
  }
}