import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { playClassRevealAudio } from "../constants/app";

// import all icons
import generic from "../images/icon_generic.png";
import boulder from "../images/icon_boulder.png";
import mystery from "../images/icon_mystery.png";
import sharp from "../images/icon_sharp.png";
import stoker from "../images/icon_stoker.png";
import strike from "../images/icon_strike.png";
import tidal from "../images/icon_tidal.png";
import tracker from "../images/icon_tracker.png";
import arrow from "../images/arrow.png";
import lightning from "../images/lightning.png";

const flipTails = keyframes`
  0% {
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
      transform: rotateY(0);
    }
    to {
      -webkit-transform: rotateY(1980deg);
      -moz-transform: rotateY(1980deg);
      transform: rotateY(1980deg);
    }
`;

const animateArrow = keyframes`
  0% {
    background-position: -1px -1px;
    }
  40% {
    background-position: -1665px -1px;
  }
  100% {
    background-position: -1965px -1px;
  }
`;

const animatelighning = keyframes`
  0% {
    background-position: -1px -1px;
    }
  100% {
    background-position: -2011px -1px
  }
`;

const FlipFrame = styled.div`
  perspective: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowAnime = styled.div`
  position: absolute;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  animation: ${animateArrow} 1.2s steps(8) forwards infinite;
  width: 206px; 
  height: 86px;
  z-index: 999;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  transform: scale(0.7) translateX(calc(-50% / 0.7));
  left: 50%;
  margin: -50px auto;

  @media (min-width: 500px) {
    transform: scale(1);
    left: auto;
    margin: 0px auto;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LightningAnime = styled.div`
  position: absolute;
  background-image: url(${lightning});
  background-repeat: no-repeat;
  left: 50%;
  transform-origin: center;
  transform: translateX(-50%) scale(1.6);
  animation: ${animatelighning} 0.7s steps(5) forwards;
  width:400px; 
  height:452px;
  z-index: 0;
`;

const StyledCoinFlip = styled.div`
  height: 400px;
  width: 400px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  
  transition: transform 1s ease-in;
  transform-style: preserve-3d;
  /* box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3),
    0 12px 20px -10px rgba(0, 0, 0, 0.4); */

  &.spinning {
    animation: ${flipTails} 2s ease-out forwards;
  }

  .genericSide,
  .chosenSide {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    background-size: 110%;
    background-position: center;
  }

  .genericSide {
    background-image: url(${generic});
    z-index: 100;
  }

  .chosenSide {
    background-image: url(${(props) => props.image});
    transform: rotateY(-180deg);
  }

  &.flipped {
    cursor: default;
    .genericSide {
      display: none;
    }

    .chosenSide {
      transform: none;
      box-shadow: 0 0 20px 10px #0ff, 0 0 10px 5px #0ff;
    }
  }
`;

const CoinFlip = ({ flipped, choice, onFinish }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startXPos, setStartXPos] = useState(0);
  const [endXPos, setEndXPos] = useState(0);
  const [choiceImage, setChoiceImage] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const icons = { boulder, mystery, sharp, stoker, strike, tidal, tracker };

  const handleSpin = () => {
    setSpinning(true);
    playClassRevealAudio();
    setTimeout(function () {
      // setLightning(true);
      onFinish();
    }, 2000);
  };
  
  const touchStart = (e) => {
    const startX = e.touches ? e.touches[0].pageX : e.pageX;
    setStartXPos(startX);
    setIsDragging(true);
  }

  // mouse Move
  const touchMove = (e) => {
    if(isDragging) {
      const endX = e.touches ? e.touches[0].pageX : e.pageX;
      setEndXPos(endX);
    }
  }

  const touchEnd = (e) => {
    setIsDragging(false);
    const offset = startXPos - endXPos;
    if(endXPos !== 0 && offset > 30 && !flipped) {
      handleSpin();
    }
  }

  useEffect(() => {
    setChoiceImage(icons[choice]);
    // eslint-disable-next-line
  }, []);

  return (
    <Container id="coin-container" onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onMouseDown={touchStart} onMouseMove={touchMove} onMouseUp={touchEnd}>
      {flipped ? (
        <FlipFrame>
          <StyledCoinFlip image={choiceImage} className="flipped">
            <LightningAnime></LightningAnime>
            <div className="chosenSide"></div>
            <div className="genericSide"></div>
          </StyledCoinFlip>
        </FlipFrame>
      ) : (
        <FlipFrame >
          <StyledCoinFlip
            image={choiceImage}
            className={spinning ? "spinning" : ""}
          >
          {!spinning && <ArrowAnime></ArrowAnime>}
            <div className="genericSide"></div>
            <div className="chosenSide"></div>
          </StyledCoinFlip>
        </FlipFrame>
      )}
    </Container>
  );
};

export default CoinFlip;
