function QuestionPickerBgIconActive({index}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359.17 246">
      <defs>
        <linearGradient id={index} x1="-1364.71" y1="5907.26" x2="-1164.71" y2="5907.26" gradientTransform="rotate(90 2434.1 3821.81)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor='#fff' stopOpacity="0"/>
          <stop offset=".05" stopColor='#fff' stopOpacity=".02"/>
          <stop offset=".11" stopColor='#fff' stopOpacity=".09"/>
          <stop offset=".18" stopColor='#fff' stopOpacity=".2"/>
          <stop offset=".26" stopColor='#fff' stopOpacity=".35"/>
          <stop offset=".34" stopColor='#fff' stopOpacity=".55"/>
          <stop offset=".43" stopColor='#fff' stopOpacity=".78"/>
          <stop offset=".5" stopColor='#fff'/>
          <stop offset=".57" stopColor='#fff' stopOpacity=".78"/>
          <stop offset=".66" stopColor='#fff' stopOpacity=".55"/>
          <stop offset=".74" stopColor='#fff' stopOpacity=".35"/>
          <stop offset=".82" stopColor='#fff' stopOpacity=".2"/>
          <stop offset=".89" stopColor='#fff' stopOpacity=".09"/>
          <stop offset=".95" stopColor='#fff' stopOpacity=".02"/>
          <stop offset="1" stopColor='#fff' stopOpacity="0"/>
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <g opacity='1' data-name="Layer 1">
          <path d="M323 3l20 20v88.56l11.93 11.92L343 135.41V223l-20 20H23L3 223V23L23 3h300m1.24-3H21.76l-.88.88-2.63 2.63L.88 20.88l-.88.88v202.48l.88.88 20 20 .88.88h302.48l.88-.88 20-20 .88-.88v-87.58l11.05-11 2.12-2.13-2.12-2.12-11.05-11V21.76l-.88-.88-17.37-17.37-2.63-2.63-.88-.88z" fill='#fff'/>
          <path fill="none" strokeMiterlimit="10" strokeWidth="3" stroke={`url(#${index})`} d="M354.93 223v-87.59L343 123.48l11.93-11.92V23"/>
        </g>
      </g>
    </svg>
  );
  
}

export default QuestionPickerBgIconActive;

