function IframeBorder() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1312.57 726">
      <defs>
        <linearGradient id="a" x1="1474.82" y1="5380.49" x2="1674.82" y2="5380.49" gradientTransform="rotate(90 3947.18 2735.36)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#17f4f9" stopOpacity="0"/>
          <stop offset=".05" stopColor="#17f4f9" stopOpacity=".02"/>
          <stop offset=".11" stopColor="#17f4f9" stopOpacity=".09"/>
          <stop offset=".18" stopColor="#17f4f9" stopOpacity=".2"/>
          <stop offset=".26" stopColor="#17f4f9" stopOpacity=".35"/>
          <stop offset=".34" stopColor="#17f4f9" stopOpacity=".55"/>
          <stop offset=".43" stopColor="#17f4f9" stopOpacity=".78"/>
          <stop offset=".5" stopColor="#17f4f9"/>
          <stop offset=".57" stopColor="#17f4f9" stopOpacity=".78"/>
          <stop offset=".66" stopColor="#17f4f9" stopOpacity=".55"/>
          <stop offset=".74" stopColor="#17f4f9" stopOpacity=".35"/>
          <stop offset=".82" stopColor="#17f4f9" stopOpacity=".2"/>
          <stop offset=".89" stopColor="#17f4f9" stopOpacity=".09"/>
          <stop offset=".95" stopColor="#17f4f9" stopOpacity=".02"/>
          <stop offset="1" stopColor="#17f4f9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="b" x1="1474.81" y1="-8429.1" x2="1674.81" y2="-8429.1" gradientTransform="matrix(0 1 1 0 8439.62 -1211.82)" xlinkHref="#a"/>
      </defs>
      <g data-name="Layer 2">
        <g opacity=".5" data-name="Layer 1">
          <path stroke="url(#a)" fill="none" strokeMiterlimit="10" strokeWidth="3" d="M1308.32 463v-87.59l-11.93-11.92 11.93-11.93V263"/>
          <path d="M1276.39 3l20 20v328.56l11.93 11.92-11.93 11.93V703l-20 20H36.17l-20-20V375.41L4.24 363.48l11.93-11.92V23l20-20h1240.22m1.25-3H34.93l-.88.88-20 20-.88.88v328.55l-11 11.05L0 363.48l2.12 2.13 11.05 11.05v327.58l.88.88 20 20 .88.88h1242.71l.88-.88 20-20 .87-.88V376.66l11-11.05 2.13-2.13-2.13-2.12-11-11.05V21.76l-.87-.88-20-20-.88-.88z" fill="#17f4f9"/>
          <path stroke="url(#b)" fill="none" strokeMiterlimit="10" strokeWidth="3" d="M4.24 463v-87.59l11.93-11.92-11.93-11.93V263"/>
        </g>
      </g>
    </svg>
  );
  
}

export default IframeBorder;

