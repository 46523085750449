import React, { useState } from "react";
import styled from "styled-components";
import flourish from "../images/flourish_white.svg";
import { BUTTONS } from '../constants/copy';

// word, flourish, bg shape

const StyledGoButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;

  .inner {
    min-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .background {
    position: absolute;
    left: 0;
    right: 0;
    width: 120px;
    top: calc(50% + 20px);
    height: 120px;
    bottom: 0;
    margin: 0 auto;
    transform-origin: center center;
    z-index: 2;
    background: #e9312f;
    transform: rotate(45deg) translate(-50%, -50%);
  }
  .foreground {
    position: relative;
    font-weight: 400;
    padding: 0 0 15px;
    z-index: 3;
    font-size: 60px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 18px;
      bottom: 0;
      background-image: url(${flourish});
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 0;
    }
  }
`;

const GoButton = ({ challengeStart }) => {
  const [started, setStarted] = useState(false);

  const handleClick = () => {
    // playButtonAudio();
    setStarted(true);
    challengeStart();
  };

  return (
    <>
      {!started && (
        <StyledGoButton onClick={handleClick}>
          <div className="inner">
            <div className="background"></div>
            <div className="foreground">{BUTTONS.button_go}</div>
          </div>
        </StyledGoButton>
      )}
    </>
  );
};

export default GoButton;
