import React, { useEffect } from "react";
import styled from "styled-components";

import ContentWrap from "../layout/ContentWrap";
import Footer from "../layout/Footer";
import DragonClasses from "../components/DragonClasses";
import siteMapGenerator from "../helpers/siteMapGenerator";
import NextButton from "../components/NextButton";
import { resetScores, setSiteMap, setChoice } from "../state/actions";
import { GetState } from "../state/stateManager";
import { PREAMBLE, LOGO_HOME } from "../constants/copy";

import flourish_homeheader from "../images/flourish_homeheader.svg";

const HomeHero = styled.div`
  img {
    max-width: 630px;
    width: 100%;
    margin: 60px auto 0;
  }
  h1 {
    font-family: 'din-condensed-bold';
    font-size: 60px;
    margin: -50px 0 20px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    padding: 30px 0;
    color: #000;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${flourish_homeheader});
    span {
      font-size: 80px;
      display: block;
    }
    @media (max-width: 768px) {
      margin: -50px 0 20px;
      font-size: 48px;
      span {
        font-size: 70px;
      }
    }
    @media (max-width: 568px) {
      margin: -40px 0 20px;
      font-size: 48px;
      span {
        font-size: 60px;
      }
    }
  }
`;

// TODO on landing CTA click, change colour, pause a second, then launch the next page.

const LandingPage = () => {
  // eslint-disable-next-line
  const [state, dispatch] = GetState();

  useEffect(() => {
    window.scrollTo(0, 0);
    // resets trait scores and result
    dispatch(resetScores());
    dispatch(setChoice());

    // Builds and stores new site map
    dispatch(setSiteMap(siteMapGenerator()));
    // eslint-disable-next-line
  }, []);

  return (
    <ContentWrap pageType="home">
      <HomeHero className="landingHero">
        <img
          src={LOGO_HOME}
          className="dragonsLogo"
          alt={PREAMBLE.logoAlt}
        />
        <h1>
          {PREAMBLE.title1} <span>{PREAMBLE.title2}</span>
        </h1>
      </HomeHero>

      <NextButton copy={PREAMBLE.cta} active={true} />

      <DragonClasses />
      <Footer />
    </ContentWrap>
  );
};

export default LandingPage;
