import React from "react";
import styled from "styled-components";

import flourish from "../images/flourish_white.svg";

const StyledIntroDescription = styled.div`
  background-image: url(${flourish});
  background-repeat: no-repeat;
  background-size: auto 10px;
  background-position: center bottom;
  padding: 20px 0 30px;
  margin: 10px 0 20px;

  h2 {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 300;
    line-height: 1;
    margin: 0 0 20px;
  }
  h3 {
    font-size: 22px;
    line-height: 1;
    font-weight: 300;
    margin: 0;
  }
`;

const IntroDescription = ({ title = "", description = "" }) => {
  return (
    <StyledIntroDescription id='scrollTo'>
      <h2>{title}</h2>
      <h3>{description}</h3>
    </StyledIntroDescription>
  );
};

export default IntroDescription;
