import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Iframe from "react-iframe";
import { getDevice, isiOS } from '../utils/deviceDetection';
import Button from './Button';
import './fullscreen.css';
import {playButtonAudio} from '../constants/app';


import IframeBorder from '../components/svg/IframeBorder';
import gestureScreen from '../images/gesture-screen.png';

import { FULLSCREEN_GESTURE } from '../constants/copy';

const StyledMazeIframeArea = styled.div`
  // height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  // max-width: 100%;
  
  transform: scale(0.5);
  margin: -120px auto -150px;
  
  @media (min-width: 400px) {
    margin: -140px auto -160px;
    transform: scale(0.6);
  }
  
  @media (min-width: ${(props) => props.width}px) {
    transform: scale(1);
    margin: 0 auto;
  }

  .iframeWrap {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    // padding-top: ${(props) => (props.height / props.width) * 100}%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

const StyledIframeArea = styled.div`
  // height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  max-width: 100%;
  margin: 0 auto 30px;
  position: relative;
  
  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  
  .iframeWrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-top: ${(props) => (props.height / props.width) * 100}%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    
    &.ios {
      @media (orientation: landscape) {
        iframe {
          position: absolute;
          top: 75px;
          left: 0;
          width: 100%;
          height: calc(100% - 75px);
          border: 0;
        }
      }
    }
  }
  
  @media only screen and (min-width: 1024px) {
    width: calc(${(props) => props.width}px + 9px);
    padding: 9px;
    
    svg {
      display: block;
    }
    
    iframe {
      --notchSize: 10px;
     
      clip-path: 
        polygon(
          0% var(--notchSize), 
          var(--notchSize) 0%, 
          calc(100% - var(--notchSize)) 0%, 
          100% var(--notchSize), 
          100% calc(100% - var(--notchSize)), 
          calc(100% - var(--notchSize)) 100%, 
          var(--notchSize) 100%, 
          0% calc(100% - var(--notchSize))
        );
    }
  }
`;

const MobileProgressButton = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: ${({shouldShow}) => shouldShow ? 'block' : 'none'};
`;

const MobileGesture = styled.div`
  background-image: url(${gestureScreen});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  z-index: 999;
  font-size: 40px;
  flex-direction: column;
  text-transform: uppercase;
  
  display: ${({disabled}) => !disabled ? 'none' : 'flex'};
  
  img {
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
  }
`;

const RotateScreenPrompt = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 56, 73, 0.6);
  z-index: 999;
  color: white;
  justify-content: center;
  display: flex;
  
  img {
    width: 100%;
  }
  
  @media (orientation: landscape) {
    display: none;
  }
  
  .rotate-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    span {
      text-transform: uppercase;
      position: absolute;
      max-height: 50px;
      max-width: 130px;
      line-height: 0.9;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
    }
    
    &__copy-1 {
      font-size: 30px;
      top: 32%;
    }
    
    &__copy-2 {
      font-size: 26px;
      top: 54%;
    }
  }
`;

const ResponsiveIframe = ({
  srcDoc,
  handlePostMessage,
  width = "500",
  height = "400",
  type = '',
  handleMobleNextPage
}) => {
  const screenSizing = isiOS() ? window.screen.width : window.orientation === 0 ? window.screen.width : window.screen.height;
  const screenPadding = isiOS() ? 75 : 0;
  const [scrollEventAttached, updateScrollEventAttached] = useState(false);
  const [shouldShowProgressButton, updateShouldShowProgressButton] = useState(false);
  const [gameCompleted, updateGameCompleted] = useState(false);
  const [isFullscreen, updateIsFullscreen] = useState(false);
  const [orientation, updateOrientation] = useState('portrait');
  const [showIosGesture, updateShowIosGesture] = useState(false);
  
  const updateAppContainer = (e) => {
    if (window.orientation === 90 || window.orientation === -90) {
      updateOrientation('landscape');
      document.getElementsByTagName('body')[0].classList.add('fullscreen');
      document.getElementById('main').style.height = `${screenSizing}px`;
      if(gameCompleted) {
        updateShouldShowProgressButton(true);
      } else {
        updateShouldShowProgressButton(false);
      }
    } else {
      updateOrientation('portrait');
      if(!isFullscreen) {
        document.getElementsByTagName('body')[0].classList.remove('fullscreen');
        document.getElementById('main').style.height = '';
        updateShouldShowProgressButton(false);
      }
    }
    
    hideIosGesture(true);
  }
  
  const handleClose = () => {
    if(!isiOS()) {
      exitFullscreen();
    }
    handleMobleNextPage();
    playButtonAudio();
  }
  
  const exitFullscreen = () => {
    updateIsFullscreen(false);
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
  
  const launchIntoFullscreen = () => {
    const element = document.getElementById('iframe-area');
    updateIsFullscreen(true);
    if(element.requestFullscreen) {
      element.requestFullscreen();
    } else if(element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if(element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if(element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
  
  const hideIosGesture = (rotate) => {
    if(document.getElementById('ios-gesture')) {
      if(typeof rotate === "boolean" && rotate) {
        if(window.orientation === 0) {
          updateShowIosGesture(false);
        } else {
          updateShowIosGesture(true);
        }
      } else if(window.scrollY >= 75 || window.orientation === 0) {
        updateShowIosGesture(false);
      } else {
        updateShowIosGesture(true);
      }
    }
  }
  
  useEffect(() => {
    if(getDevice() === 'smartphone' && type !== 'maze') {
      updateAppContainer();
      window.addEventListener('orientationchange', updateAppContainer);
      
      if(isiOS()) {
        hideIosGesture();
        updateScrollEventAttached(true);
        window.addEventListener('scroll', hideIosGesture);
      }
    }
    
    const handler = (event) => {
      console.log('handler', event);
      const data = event.data;
      handlePostMessage(event.data);
      
      if(data.id === "GAME_COMPLETE") {
        if(getDevice() === 'smartphone') {
          updateGameCompleted(true);
          updateShouldShowProgressButton(true);
        } else {
          if(document.getElementById('next-button')) {
            window.scrollTo(0, document.getElementById('next-button').offsetTop);
          }
        }
      }
    };
    
    window.addEventListener("message", handler);
    // clean up
    return () => {
      if(getDevice() === 'smartphone') {
        window.removeEventListener('orientationchange', updateAppContainer);
        
        if (document.getElementsByTagName('body')[0].classList.contains('fullscreen')) {
          document.getElementsByTagName('body')[0].classList.remove('fullscreen');
          document.getElementById('main').style.height = '';
        }
        
        if(isiOS() && scrollEventAttached) {
          window.removeEventListener('scroll', hideIosGesture);
        }
      }
      
      window.removeEventListener("message", handler)};
    // eslint-disable-next-line
  }, []);
  
  let hideGesture = false;
  if(orientation === 'portrait') {
    hideGesture = false;
  } else if (!isFullscreen) {
    hideGesture = true;
  } else {
    hideGesture = false;
  }
  
  // !!!!!!!!
  // TODO: MOVE HARDCODED TEXT IN THE LOCALE COPY
  // !!!!!!!
  
  if(type === 'maze') {
    return (
      <StyledMazeIframeArea width={width} height={height} type={type}>
        <div className="iframeWrap">
          <Iframe url={srcDoc} allowfullscreen/>
        </div>
      </StyledMazeIframeArea>
    );
  } else {
    return (
      <>
        {getDevice() === 'smartphone' && (
          <RotateScreenPrompt>
            <img src={require(`../images/rotate-screen.png`).default} alt='fullscreen prompt'/>
            <div className="rotate-text">
              <span className="rotate-text rotate-text__copy-1">{FULLSCREEN_GESTURE.rotate_screen_1}</span>
              <span className="rotate-text rotate-text__copy-2">{FULLSCREEN_GESTURE.rotate_screen_2}</span>
            </div>
          </RotateScreenPrompt>
        )}
        <StyledIframeArea id="iframe-area" width={width} height={height} type={type} screenPadding={screenPadding}>
          <IframeBorder />
          <div className={`iframeWrap` + (isiOS() ? " ios" : ' other')} style={{height: `calc(100% + ${screenPadding}px)`}}>
            {
              isiOS() ? (
                <MobileGesture id="ios-gesture" disabled={showIosGesture}>{FULLSCREEN_GESTURE.ios_fullscreen_gesture}</MobileGesture>
              ) : (
                <MobileGesture disabled={hideGesture} onClick={launchIntoFullscreen}>{FULLSCREEN_GESTURE.android_fullscreen_gesture}</MobileGesture>
              )
            }
            {getDevice() === 'smartphone' && <MobileProgressButton id="mobile-click-button" shouldShow={shouldShowProgressButton} onClick={handleClose}><Button>{FULLSCREEN_GESTURE.mobile_close_button}</Button></MobileProgressButton>}
            <Iframe url={srcDoc} allowfullscreen/>
          </div>
        </StyledIframeArea>
      </>
    );
  }
};

export default ResponsiveIframe;
