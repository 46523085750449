import React from "react";
import styled from "styled-components";

import headerBG from "../images/bg_header.png";

const StyledQuestionTitle = styled.h2`
  background-image: url(${headerBG});
  background-repeat: no-repeat;
  background-size: auto 100%;
  text-transform: uppercase;
  background-position: center;
  font-size: 30px;
  line-height: 0.8;
  margin: 10px auto;
  padding: 20px 0 40px;
  font-weight: 400;
  font-family: 'din-condensed-bold';
  
  @media only screen and (min-width: 768px) {
    font-size: 70px;
  }
`;

const QuestionTitle = ({ children }) => {
  return <StyledQuestionTitle>{children}</StyledQuestionTitle>;
};

export default QuestionTitle;
