import React, { useState } from "react";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { APPENDIX } from "../constants/copy";
import NextButton from "../components/NextButton";
import GameFrame from "../layout/GameFrame";

import QuestionDragBgIcon from "../components/svg/QuestionDragBgIcon";
import QuestionDraggingBgIcon from "../components/svg/QuestionDraggingBgIcon";

import draging_bg from "../images/draging_bg.png";

const DragList = styled.ul`
  user-select: none;
  list-style: none;
  margin: 0 auto -200px;
  padding: 0px;
  width: 620px;
  position: relative;
  overflow: hidden;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  min-height: 500px;
  
  @media only screen and (min-width: 520px) {
    margin: 0 auto -70px;
  }
  
  @media only screen and (min-width: 768px) {
  margin: 0 auto;
  }
  
  .inActiveDrag {
    position: relative;
    
    .active-bg {
      opacity: 0;
    }
    
    svg {
      opacity: 0;
    }
    
    .answer-image {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
  }
`;

const DragBG = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Prefs = styled.li`
  user-select: none;
  margin: 5px;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  width: 160px;
  
  @media only screen and (min-width: 520px) {
    width: 250px;
  }
  
  @media only screen and (min-width: 768px) {
    width: 300px;
  }
  
  p {
    font-size: 20px;
    margin: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media only screen and (min-width: 520px) {
      font-size: 30px;
    }
    
    @media only screen and (min-width: 768px) {
      font-size: 40px;
    }
  }
  
  &.active {
    p {
      text-shadow: 0px 0px 10px rgba(152, 221, 255, 1);
    }
  }
`;

const DragItem = styled.li`
  user-select: none;
  position: relative;
  z-index: 9;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  cursor: grab;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 64px 20px 22px;
  height: 69px;
  text-align: center;
  width: 130px;
  
  @media only screen and (min-width: 520px) {
    margin: 68px 20px 23px;
    height: 117px;
    width: 220px;
  }
  
  @media only screen and (min-width: 768px) {
    height: 142px;
    width: 270px;
  }
  
  svg {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  
  .overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    opacity: 0;
    font-weight: bold;
    text-shadow: 0px 2px #000;
    
    
    @media only screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
  
  .answer-image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  
  .active-bg {
    opacity: 1;
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 138%;
  }
  
  p {
    margin: 0;
    padding: 50px 0 0;
    text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.8);
  }
`;

const SortableItem = SortableElement(({ index, content, indexed, image, active }) => {
  return (
    <DragItem className={`inActiveDrag ${indexed}`}>
      <QuestionDraggingBgIcon />
      <img className='active-bg' src={draging_bg} alt={content} />
      <img src={require(`../images/${image}`).default} alt={content} className="answer-image" />
      <span className={`overlay-text ${active ? 'active' : ''}`}>{content}</span>
    </DragItem>
  )
});

const SortableList = SortableContainer(({ items, activeItem }) => {
  return (
    <DragList id="list">
      <DragBG>
        {items.map(({ content }, index) => (
          <>
            <Prefs id={`drag-item__${index}`} className={activeItem === index ? 'active' : ''} key={`${index}${index}`}>
              <p>{APPENDIX.preferences[index]}</p>
                <QuestionDragBgIcon active={activeItem === index} />
            </Prefs>
          </>
        ))}
      </DragBG>
      {items.map(({ content, image }, index) => {
        return (
          <>
            <SortableItem key={index} index={index} content={content} indexed={index} image={image} active={activeItem === index}/>
          </>
        )
      })}
    </DragList>
  );
});

const DndReorder = ({ answers }) => {
  const [drag, setDrag] = useState(answers);
  const [activeItem, setActiveItem] = useState(null);
  
  const onSortStart = ({index})  => {
    setActiveItem(index);
  };
  
  const onSortOver = ({newIndex})  => {
    setActiveItem(newIndex);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setActiveItem(null);
    setDrag(arrayMove(drag, oldIndex, newIndex));
  };
  let scoreObj = { [drag[0].trait]: 30 };
  return (
    <>
      <GameFrame>
        <SortableList items={drag} onSortEnd={onSortEnd} onSortOver={onSortOver} onSortStart={onSortStart} axis="xy" activeItem={activeItem}/>
      </GameFrame>
      <NextButton active={true} scoring={scoreObj} />
    </>
  );
};

export default DndReorder;
