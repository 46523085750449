/* eslint-disable */

import { AnimatePresence, motion } from "framer-motion";

import { GetState } from "./state/stateManager";
import { nextPage } from "./state/actions";
import ResultsPage from "./containers/ResultsPage";
import ScorePage from "./containers/ScorePage";
import LandingPage from "./containers/LandingPage";
import QuizPage from "./containers/QuizPage";

const PageAnimation = ({ children }) => {
  // eslint-disable-next-line
  const [state, dispatch] = GetState();
  return (
    <motion.div
      key={state.currentPage}
      exit="out"
      animate="in"
      initial="out"
      variants={pageTransition}
      onAnimationComplete = {() => {
        if(document.getElementById('scrollTo')) {
          window.scrollTo(0, document.getElementById('scrollTo').offsetTop);
        }
      }}
    >
      {children}
    </motion.div>
  );
};

const pageTransition = {
  in: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
  out: {
    x: -100,
    opacity: 0,
    transition: {
      delay: 1,
    },
  },
};

function Routes() {
  const [state, dispatch] = GetState();
  const currentPage = state.currentPage;

  const handlePageSkip = () => {
    dispatch(nextPage(state.currentPage));
  };

  // Clincher logic
  let clincherNeeded = false;
  // Grab score values
  const results = Object.values(state.score);
  // sort by size and remove the lowest
  results
    .sort(function (a, b) {
      return b - a;
    })
    .pop();
  // remove any duplicates
  const removeDuplicates = (array) => {
    return array.filter((a, b) => array.indexOf(a) === b);
  };
  const distinctResults = removeDuplicates(results).length;
  // if any of the top three are the same, then distinctResults won't be 3, and clincherNeeded
  distinctResults !== 3 ? (clincherNeeded = true) : (clincherNeeded = false);
  // console.log(distinctResults, clincherNeeded);

  const totalQuestions = state.sitemap.length;

  return (
    <AnimatePresence>
      {currentPage === 0 && (
        <PageAnimation >
          <LandingPage />
        </PageAnimation>
      )}
      {currentPage > 0 && currentPage < totalQuestions && (
        <PageAnimation>
          <QuizPage />
        </PageAnimation>
      )}

      {currentPage === totalQuestions && (
        <PageAnimation>
          <QuizPage type="lastQuestion" />
        </PageAnimation>
      )}
      {currentPage === totalQuestions + 1 && (
        <PageAnimation>
          <ScorePage />
        </PageAnimation>
      )}
      {currentPage > totalQuestions + 1 && (
        <PageAnimation>
          <ResultsPage />
        </PageAnimation>
      )}
    </AnimatePresence>
  );
}

// change results page to score page
// add new results page

export default Routes;
