import * as actions from "./actions";

const reset = { speed: 0, firepower: 0, bravery: 0, cunning: 0, userName: "", downloadImage: '' };

const reducer = (state, action) => {
  console.log("Action:", action);
  switch (action.type) {
    case actions.PAGE_CHANGED:
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({ ...state, currentPage: action.payload })
      );
      return {
        ...state,
        currentPage: action.payload,
      };

    case actions.SCORE_UPDATED:
      console.log("SCORE_UPDATED", action.payload);
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({
          ...state,
          score: { ...state.score, ...action.payload },
        })
      );
      return {
        ...state,
        score: { ...state.score, ...action.payload },
      };

    case actions.SET_DOWNLOAD_IMAGE:
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({ ...state, downloadImage: action.payload })
      );
      return { ...state, downloadImage: action.payload };
      
    case actions.SCORE_RESET:
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({ ...state, score: reset })
      );
      return { ...state, score: reset };

    case actions.SITEMAP_GENERATED:
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({ ...state, sitemap: action.payload })
      );
      return {
        ...state,
        sitemap: action.payload,
      };

    case actions.DRAGON_CHOSEN:
      sessionStorage.setItem(
        "httydState",
        JSON.stringify({ ...state, chosenResult: action.payload })
      );
      return {
        ...state,
        chosenResult: action.payload,
      };
    default:
      break;
  }
};

export default reducer;
