import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import ContentWrap from "../layout/ContentWrap";
import resultParser from "../helpers/resultParser";
import LogoArea from "../components/LogoArea";
import QuestionTitle from "../components/QuestionTitle";
import NextButton from "../components/NextButton";

import ScoreBg from "../components/svg/ScoreBg";

import { setChoice } from "../state/actions";
import { GetState } from "../state/stateManager";
import { CLOSING } from "../constants/copy";

const Frame = styled.div`
  text-align: left;
  width: 400px;
  margin: -50px auto -80px;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  left: 50%;
  transform: scale(0.7) translateX(calc(50% - 485px));
  
  @media only screen and (min-width: 420px) {
    transform: scale(1);
    left: auto;
    margin: 50px auto 40px;
  }

  span {
    padding-left: 50px;
  }
`;

const Bar = styled.div`
  height: 52px;
  background: ${(props) => props.color};
  position: relative;
  margin-left: 16px;
  opacity: 0;
  
  transition: opacity 0.3s ease-in-out, width 0.7s ease;
  
  &:after {
    content: "";
    position: absolute;
    right: -14px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 15px solid ${(props) => props.color};
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
  }
  
  &:before {
    content: "";
    position: absolute;
    left: -14px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 15px solid ${(props) => props.color};
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
  }
`;

const BarContainer = styled.div `
  z-index: 10;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  
  svg {
    position: absolute;
  }
`;

const TraitGlyph = styled.img`
  height: 35px;
  position: absolute;
  left: 0;
`;

function ScorePage() {
  const revealRef = useRef(null);
  const [state, dispatch] = GetState();
  const [stateSpeedPercent, updateStateSpeedPercent] = useState(0);
  const [stateFirepowerPercent, updateStateFirepowerPercent] = useState(0);
  const [stateBraveryPercent, updateStateBraveryPercent] = useState(0);
  const [stateCunningPercent, updateStateCunningPercent] = useState(0);
  
  useEffect(() => {
    dispatch(setChoice(resultParser(state.score)));
    
    setTimeout(() => {
      updateStateSpeedPercent(state.score.speed);
      updateStateFirepowerPercent(state.score.firepower);
      updateStateBraveryPercent(state.score.bravery);
      updateStateCunningPercent(state.score.cunning);
    }, 750);
    
    setTimeout(() => {
      window.scrollTo(0, revealRef.current.offsetTop);
    }, 1800);
    
    // eslint-disable-next-line
  }, []);
  
  return (
    <ContentWrap>
      <LogoArea />
      <QuestionTitle>{CLOSING[1].title}</QuestionTitle>
      <Frame>
        <TraitGlyph src={require(`../images/glyph_speed.svg`).default} />
        <span>{CLOSING[1].speed}</span>
        <BarContainer>
          <div>
            <ScoreBg color="#f03ee3" />
            <Bar style={{width: `${stateSpeedPercent * 3}px`, opacity: (stateSpeedPercent !== 0 ? 1 : 0)}} color="#f03ee3" />
          </div>
        </BarContainer>
        <TraitGlyph src={require(`../images/glyph_firepower.svg`).default} />
        <span>{CLOSING[1].firepower}</span>
        <BarContainer >
          <div>
            <ScoreBg color="#f46b49" />
            <Bar style={{width: `${stateFirepowerPercent * 3}px`, opacity: (stateFirepowerPercent !== 0 ? 1 : 0)}} color="#f46b49" />
          </div>
        </BarContainer>
        <TraitGlyph src={require(`../images/glyph_bravery.svg`).default} />
        <span>{CLOSING[1].bravery}</span>
        <BarContainer color="#21cd9e">
          <div>
            <ScoreBg color="#21cd9e" />
            <Bar style={{width: `${stateBraveryPercent * 3}px`, opacity: (stateBraveryPercent !== 0 ? 1 : 0)}} color="#21cd9e" />
          </div>
        </BarContainer>
        <TraitGlyph src={require(`../images/glyph_cunning.svg`).default} />
        <span>{CLOSING[1].cunning}</span>
        <BarContainer>
          <div>
            <ScoreBg color="#1898dc" />
            <Bar style={{width: `${stateCunningPercent * 3}px`, opacity: (stateCunningPercent !== 0 ? 1 : 0)}} color="#1898dc" />
          </div>
        </BarContainer>
      </Frame>
      <br />
      <div ref={revealRef}>
        <NextButton copy={CLOSING[1].cta} />
      </div>
    </ContentWrap>
  );
}

export default ScorePage;
