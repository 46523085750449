import React from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  background: ${props => props.active ? '#17f4f9' : '#e9312f'};
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  padding: 0 30px;
  margin: 10px;
  height: 60px;
  line-height: 60px;
  cursor: ${props => props.staticButton ? 'auto' : 'pointer'};
  display: inline-block;
  --bigNotch: 30px;
  --smallNotch: 15px;
  user-select: none;
  min-width: 230px;
  clip-path: polygon(
    0% var(--bigNotch),
    var(--smallNotch) 0%,
    calc(100% - var(--smallNotch)) 0%,
    100% var(--bigNotch),
    100% calc(100% - var(--bigNotch)),
    calc(100% - var(--smallNotch)) 100%,
    var(--smallNotch) 100%,
    0% calc(100% - var(--bigNotch))
  );
  svg {
    height: 35px;
    margin: 0 5px -4px 0;
  }
`;

const ButtonShadow = styled.div`
  ${(props) => props.active && `
    filter: drop-shadow(0px 0px 8px rgba(174,230,255,0.75));
  `}
`;

const Button = ({ children, clickHandler, active, staticButton = false }) => {
  return (
    <span>
      <ButtonShadow active={active}>
        <StyledButton active={active} onClick={clickHandler} staticButton={staticButton}>{children}</StyledButton>
      </ButtonShadow>
    </span>
  );
};

export default Button;
