import React from "react";
import styled, { css } from "styled-components";
import { GetState } from "../state/stateManager";

const ProgressWrap = styled.div`
  margin: 20px auto;
  max-width: 900px;
  text-align: center;
`;

const ProgressList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ProgressItem = styled.li`
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin: 2px;

  ${(props) =>
    props.stage === "pagePending" &&
    css`
      background-image: url(${require(`../images/page_todo.svg`).default});
    `}

  ${(props) =>
    props.stage === "pageActive" &&
    css`
      background-image: url(${require(`../images/page_doing.svg`).default});
      &:before {
        content: "";
        background: transparent;
        height: 2px;
        width: 2px;
        position: absolute;
        top: 9px;
        left: 9px;
        box-shadow: 0 0 20px 10px #0ff;
      }
    `}

  ${(props) =>
    props.stage === "pageDone" &&
    css`
      background-image: url(${require(`../images/page_done.svg`).default});
    `}
`;

const ProgressIndicator = () => {
  // eslint-disable-next-line
  const [state, dispatch] = GetState();
  const currentPage = state.currentPage;
  const totalPages = state.sitemap.length;
  const progressItems = [];

  for (var i = 0; i < totalPages; i++) {
    let page = i + 1;
    let progress = "";
    if (page > currentPage) {
      progress = "pagePending";
    } else if (page === currentPage) {
      progress = "pageActive";
    } else {
      progress = "pageDone";
    }
    progressItems.push(progress);
  }

  return (
    <ProgressWrap id="progress-bar">
      <ProgressList>
        {progressItems.map((item, i) => (
          <ProgressItem key={i} stage={item}></ProgressItem>
        ))}
      </ProgressList>
    </ProgressWrap>
  );
};

export default ProgressIndicator;
