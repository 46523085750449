/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import GoButton from "../components/GoButton";
import NextButton from "../components/NextButton";
import "./Puzzle.css";
import PuzzlePiece from '../components/PuzzlePiece';
import styled from "styled-components";

import {
  PUZZLE_DEFAULT_WIDTH,
  PUZZLE_DEFAULT_HEIGHT,
  PUZZLE_CONTAINER_DEFAULT_WIDTH,
  PUZZLE_SOLTS, 
  PUZZLE_CONTAINER_DEFAULT_HEIGHT,
  PUZZLE_DEFAULT_SPACING,
  PUZZLE_PIECES_BY_IDS,
  PUZZLE_COMPLETED_SOLTS,
  PUZZLE_PIECES_POSITIONS,
  debounce
} from "../constants/puzzleConstants";

const PuzzleBG = styled.div`
  width: ${(props) => props.baseWidth}px;
  height: ${(props) => props.baseHeight}px; 
  display: flex;
  flex-wrap: wrap;
  position: absolute; 
  left: 50%;
  transform: translateX(-50%) translateY(0);
  
  @media (min-width: 768px) {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .puzzle-bg-completed {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;
    position: relative;
    
    &--show {
      opacity: 1;
    }
    
    &--hide {
      opacity: 0;
    }
  }
  
  .puzzle-bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: 133%;
  }
`;

const ScalableContainer = styled.div`
  width: 80%;
  height: calc(${(props) => props.baseHeight}px * ${(props) => props.baseScale});
  margin: 0 auto 40px auto;
  
  @media only screen and (min-width: 768px) {
    width: 100%;
    
  }
`;

const ParentContainer = styled.section`
  width: ${(props) => props.baseWidth}px;
  height: ${(props) => props.baseHeight}px;
  
  position: relative;
  transform: scale(${(props) => props.baseScale}) translateX(calc(-50% / ${(props) => props.baseScale}));
  transform-origin: center top; 
  left: 50%;
`;

const DndUpdatedPuzzle = () => {
  const [timer, setTimer] = useState(20);
  const [isActive, setIsActive] = useState(false);
  const [finished, setFinished] = useState(false);
  const [score, setScore] = useState(10);
  const [viewType, updateViewType] = useState(window.innerWidth < 768 ? 'mobile' : 'desktop');
  const countRef = useRef(null);
  
  const [scale, updateScale] = useState(1);
  const scalableContainer = useRef();
  const puzzleRef = useRef(null);
  const parentRef = useRef(null);
  
  const [solt1, updateSlot1] = useState(null);
  const [solt2, updateSlot2] = useState(null);
  const [solt3, updateSlot3] = useState(null);
  const [solt4, updateSlot4] = useState(null);
  
  const [defaultPosition1, updateDefaultPosition1] = useState(PUZZLE_PIECES_POSITIONS.piece_1.default_position[viewType]);
  const [defaultPosition2, updateDefaultPosition2] = useState(PUZZLE_PIECES_POSITIONS.piece_2.default_position[viewType]);
  const [defaultPosition3, updateDefaultPosition3] = useState(PUZZLE_PIECES_POSITIONS.piece_3.default_position[viewType]);
  const [defaultPosition4, updateDefaultPosition4] = useState(PUZZLE_PIECES_POSITIONS.piece_4.default_position[viewType]);
  
  const handleTimerStart = () => {
    if (isActive) return;
    const height = document.getElementById('scrollTo').getBoundingClientRect().height;
    window.scrollTo(0, window.scrollY +  height);
    setIsActive(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 0.01);
    }, 10);
  };

  const handleTimerEnd = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setFinished(true);
    //If they take less than 5 seconds, the user is awarded the maximum 30 speed points. For 6-10 seconds they get 20 points. For 11+ seconds they get the minimum 10 points.
    if (timer >= 15) {
      setScore(30);
    } else if (timer >= 10) {
      setScore(20);
    }
  };

  const checkFinishedPuzzle = () => {
    if(solt1 !== PUZZLE_COMPLETED_SOLTS.slot_1) return;
    if(solt2 !== PUZZLE_COMPLETED_SOLTS.slot_2) return;
    if(solt3 !== PUZZLE_COMPLETED_SOLTS.slot_3) return;
    if(solt4 !== PUZZLE_COMPLETED_SOLTS.slot_4) return;
    
    handleTimerEnd();
  }
  
  const updatePiecePosition = (id, pos) => {
    switch (id) {
      case 'piece_1':
        updateDefaultPosition1({x: pos.x, y: pos.y});
        break;
      case 'piece_2':
        updateDefaultPosition2({x: pos.x, y: pos.y});
        break;
      case 'piece_3':
        updateDefaultPosition3({x: pos.x, y: pos.y});
        break;
      default:
        updateDefaultPosition4({x: pos.x, y: pos.y});
        break;
    }
  }
  
  const getPiecePosition = (id) => {
    switch (id) {
      case 'piece_1':
        return defaultPosition1;
        break;
      case 'piece_2':
      return defaultPosition2;
        break;
      case 'piece_3':
      return defaultPosition3;
        break;
      default:
      return defaultPosition4;
        break;
    }
  }
  
  const updateCurrentSlottedPiece = (pieceBeingMoved, pieceBeingPlaced, callback) => {
    const emptySolt = checkForAvaiableSolt();
    
    const puzzlePlacement = puzzleRef.current.getBoundingClientRect();
    const parentContainer = parentRef.current.getBoundingClientRect();
    const relativeTop = (puzzlePlacement.top / scale) - (parentContainer.top / scale);
    const relativeLeft = (puzzlePlacement.left / scale) - (parentContainer.left / scale);
    if(emptySolt !== 'none') {
      const coords = {x: relativeLeft + PUZZLE_SOLTS[emptySolt].x, y: relativeTop + PUZZLE_SOLTS[emptySolt].y};
      updatePiecePosition(pieceBeingMoved, coords);
      
      updateCurrentSlot(emptySolt, pieceBeingMoved);
    } else {
      const previousPos = getPiecePosition(pieceBeingPlaced);
      updatePiecePosition(pieceBeingMoved, previousPos);
      const currentSlot = checkForCurrentPieceSolt(pieceBeingPlaced);
      
      updateCurrentSlot(currentSlot, pieceBeingMoved);
    }
    
    callback();
  }
  
  const updateCurrentSlot = (id, piece) => {
    switch (id) {
      case 'slot_1':
        updateSlot1(piece);
        break;
      case 'slot_2':
        updateSlot2(piece);
        break;
      case 'slot_3':
        updateSlot3(piece);
        break;
      default:
        updateSlot4(piece);
        break;
    }
  }
  
  const slotCallback = (pieceID, slot, slotPos) => {
    
    const isAlreadySlotted = checkForCurrentPieceSolt(pieceID);
    if(isAlreadySlotted !== 'none') {
      updateCurrentSlot(isAlreadySlotted, null);
    }
    
    switch (slot) {
      case 'slot_1':
        if(solt1 !== null) {
          updateCurrentSlottedPiece(solt1, pieceID, () => {
            updateSlot1(pieceID)
          });
        } else {
          updateSlot1(pieceID);
        }
      
        break;
      case 'slot_2':
      
        if(solt2 !== null) {
          updateCurrentSlottedPiece(solt2, pieceID, () => {
            updateSlot2(pieceID);
          });
        } else {
          updateSlot2(pieceID);
        }
        
        break;
      case 'slot_3':
      
        if(solt3 !== null) {
          updateCurrentSlottedPiece(solt3, pieceID, () => {
            updateSlot3(pieceID);
          });
        } else {
          updateSlot3(pieceID);
        }
        
        break;
      default:
        if(solt4 !== null) {
          updateCurrentSlottedPiece(solt4, pieceID, () => {
            updateSlot4(pieceID);
          });
        } else {
          updateSlot4(pieceID);
        }
        
        break;
    }
    
    updatePiecePosition(pieceID, slotPos);
  }
  
  const checkForAvaiableSolt = () => {
    if(solt1 === null) return 'slot_1';
    if(solt2 === null) return 'slot_2';
    if(solt3 === null) return 'slot_3';
    if(solt4 === null) return 'slot_4';
    
    return 'none';
  }
  
  const checkForCurrentPieceSolt = (id) => {
    if(solt1 === id) return 'slot_1';
    if(solt2 === id) return 'slot_2';
    if(solt3 === id) return 'slot_3';
    if(solt4 === id) return 'slot_4';
    
    return 'none';
  }
  
  const dragCallback = (id, slot) => {}
  
  // functions
  const deboucedFunction = debounce(
    function() {
      if(window.innerWidth < 768) {
        updateViewType('mobile');
      } else {
        updateViewType('desktop');
        
      }
      if (parentRef.current && scalableContainer.current) {
        const widthParent = parentRef.current.clientWidth;
        const heightParent = parentRef.current.clientHeight;

        const width = scalableContainer.current.clientWidth;
        const height = scalableContainer.current.clientHeight;

        const scale = Math.min(width / widthParent, height / heightParent);

        updateScale(scale);
      }
    },
    300
  );
  
  useEffect(() => {
    checkFinishedPuzzle();
  }, [solt1, solt2, solt3, solt4]);
  
  useEffect(() => {
    if(window.innerWidth < 768) {
      updateViewType('mobile');
    } else {
      updateViewType('desktop');
      
    }
    
    if (parentRef.current && scalableContainer.current) {
      const widthParent = parentRef.current.clientWidth;
      const heightParent = parentRef.current.clientHeight;

      const width = scalableContainer.current.clientWidth;
      const height = scalableContainer.current.clientHeight;

      const scale = Math.min(width / widthParent, height / heightParent);

      updateScale(scale);
    }

    window.addEventListener('resize', deboucedFunction);
    return () => {
      window.removeEventListener('resize', deboucedFunction);
    };
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      handleTimerEnd();
    }
  }, [timer]);
  
  const baseHeight = finished ? PUZZLE_DEFAULT_HEIGHT : (PUZZLE_CONTAINER_DEFAULT_HEIGHT[viewType] + PUZZLE_DEFAULT_SPACING * 2);
  const scoreObj = { speed: score };
  return (
    <>
      <ScalableContainer ref={scalableContainer} baseHeight={baseHeight} baseScale={scale} id="container">
        <ParentContainer ref={parentRef} baseWidth={(PUZZLE_CONTAINER_DEFAULT_WIDTH[viewType] + PUZZLE_DEFAULT_SPACING * 2)} baseHeight={baseHeight} baseScale={scale}>    
          <PuzzleBG ref={puzzleRef} baseWidth={PUZZLE_DEFAULT_WIDTH} baseHeight={PUZZLE_DEFAULT_HEIGHT}>
            <GoButton challengeStart={handleTimerStart} />
            {!finished && <img className="puzzle-bg" src={require(`../images/puzzle-bg.png`).default} alt="puzzle background" />}
            <img className={"puzzle-bg-completed" + (finished ? " puzzle-bg-completed--show": " puzzle-bg-completed--hide")} src={require(`../images/puzzle-bg-completed.png`).default} alt="puzzle completed" />
          </PuzzleBG>
          {
            !finished && 
            PUZZLE_PIECES_BY_IDS.map((value, index) => {
              let posValue = '';
              switch (index) {
                case 0:
                  posValue = defaultPosition1;
                  break;
                case 1:
                  posValue = defaultPosition2;
                  break;
                case 2:
                  posValue = defaultPosition3;
                  break;
                default:
                  posValue = defaultPosition4;
                  break;
              }
              return (
                <PuzzlePiece key={index} index={value} puzzleRef={puzzleRef} parentRef={parentRef} scale={scale} viewType={viewType} disableMove={!isActive} callback={slotCallback} dragCallback={dragCallback} defaultPosition={posValue}/>
              )
            })
          }
        </ParentContainer>
      </ScalableContainer>
      <NextButton
        timeLeft={parseFloat(timer).toFixed(0)}
        active={finished}
        scoring={scoreObj}
      />
    </>
  );
};

export default DndUpdatedPuzzle;
