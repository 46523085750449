import React, { useState } from "react";
import styled, { css } from "styled-components";
import NextButton from "../components/NextButton";
import GameFrame from "../layout/GameFrame";

import sliderIcon from "../images/slider-range.png";

import active_bg from "../images/active_bg.png";
import non_active_bg from "../images/non_active_bg.png";

const FlexWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Choices = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 5px;
  box-sizing: border-box;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.3s ease-in;
  z-index: 1;
  position: relative;
  
  .answer__title,
  .active__bg {
    opacity: 0;
  }
  
  .non-active__bg {
    opacity: 1;
    position: absolute;
    transform-origin: center;
    width: 100%;
    z-index: 10;
  }
  
  .answer__image {
    position: absolute;
    transform-origin: center;
    width: 100%;
  }
  
  ${(props) =>
    props.focus === "selected" &&
    css`
      z-index: 10;
      transform: scale(1.5, 1.5);
      
      .answer__title {
        margin: 0;
        opacity: 1;
        position: absolute;
        max-width: 90px;
        color: #fff;
        text-shadow: 0px 2px 2px rgba(0,0,0,0.75);
        z-index: 11;
        font-size: 24px;
      }
      
      .active__bg  {
        opacity: 1;
        position: absolute;
        transform-origin: center;
        width: 166%;
        z-index: 10;
      }
      
      .non-active__bg {
        opacity: 0;

      }
    `}
`;

const SliderContainer = styled.div`
  transform: scale(0.5);
  position: relative;
  margin-top: -40px;
  margin-bottom: -50px;
  min-height: 240px;

  @media only screen and (min-width: 600px) {
    transform: scale(0.7);
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 725px) {
    transform: scale(1);
    margin-top: 50px;
    margin-bottom: 70px;
  }
`;

const Slider = styled.input`
  margin: 20px auto 0;
  display: block;
  width: 545px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  appearance: none;
  background: none;
  border-top: solid #17f4f9 2px;
  border-bottom: solid #17f4f9 2px;
  padding: 10px 0;
  z-index: 10;

  &:after,
  &:before {
    content: '';
    width: 33px;
    height: 33px;
    top: 5px;
    transform: rotate(45deg);
    position: absolute;
  }
  
  &:after {
    border-top: solid #17f4f9 2px;
    border-right: solid #17f4f9 2px;
    right: -18px;
  }
  
  &:before {
    left: -18px;
    border-bottom: solid #17f4f9 2px;
    border-left: solid #17f4f9 2px;
  }
  
  &:focus {
    outline: none;
  }
  
  &::-webkit-slider-thumb {
    width: 63px;
    height: 25px;
    -webkit-appearance: none;
    background-image: url(${sliderIcon});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
    // box-shadow: 0 0 4px 10px rgba(174,230,255,0.75);
  }

  &::-moz-range-thumb {
    width: 63px;
    height: 25px;
    -webkit-appearance: none;
    background-image: url(${sliderIcon});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
  }

  &::-ms-thumb {
    width: 63px;
    height: 25px;
    -webkit-appearance: none;
    background-image: url(${sliderIcon});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
  }
`;

const SliderQuestion = ({ answers }) => {
  const [sliderValue, setSliderValue] = useState(0);

  const handleChange = (e) => {
    setSliderValue(parseInt(e.target.value));
  };

  const handleClick = (index) => {
    setSliderValue(index);
  };

  let scoreObj = { [answers[sliderValue].trait]: 30 };

  return (
    <>
      <GameFrame>
        <SliderContainer id="slider-question">
          <FlexWrap>
            {answers.map((answer, index) => (
              <Choices
                focus={sliderValue === index ? "selected" : ""}
                scale={sliderValue === index ? "1.5, 1.5" : " 1, 1"}
                zIndex={sliderValue === index ? "10" : "1"}
                key={index}
                onClick={() => handleClick(index)}
              >
                <img className="active__bg" src={active_bg} alt="active" />
                <img className="non-active__bg" src={non_active_bg} alt="non-active" />
                <img className="answer__image" src={require(`../images/${answer.image}`).default} alt="active" />
                <p className="answer__title">{answer.content}</p>
              </Choices>
            ))}
          </FlexWrap>

          <Slider
            type="range"
            min="0"
            max="3"
            value={sliderValue}
            onChange={handleChange}
          />
        </SliderContainer>
      </GameFrame>

      <NextButton active={true} scoring={scoreObj} />
    </>
  );
};
export default SliderQuestion;
