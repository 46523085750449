import React from "react";
import styled from "styled-components";
import DragonClass from "../components/DragonClass";
import { PREAMBLE } from "../constants/copy";

import flourish from "../images/flourish_landingsub.svg";
import overwroughtBG from "../images/bg_classes.svg";

const Classes = styled.div`
  width: 900px;
  margin: -140px auto;
  position: relative;
    left: 50%;
    transform: translateX(-50%) scale(0.5);
    
    @media only screen and (min-width: 460px) {
      margin: -80px auto;
      transform: translateX(-50%) scale(0.7);
    }
    
    @media only screen and (min-width: 900px) {
      left: auto;
      transform: scale(1);
      margin: 0 auto;
      
    }
  
`;

const OverWroughtBackground = styled.div`
  padding: 20px 0px 60px;
  background-size: 92%;
  background-repeat: no-repeat;
  background-position: center -25px;
  background-image: url(${overwroughtBG});
  // @media (max-width: 768px) {
  //   background-size: 102%;
  // }
  // @media (max-width: 568px) {
  // }
`;

const LandingSubtitle = styled.h2`
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 0 0 20px;
  color: #000;
  background-size: 200px auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url(${flourish});
`;

const DragonClasses = () => {
  const { resultOptions, subtitle } = PREAMBLE;
  return (
    <Classes>
      <LandingSubtitle>{subtitle}</LandingSubtitle>
      <OverWroughtBackground>
        {resultOptions.map((dragonClass) => (
          <DragonClass
            key={dragonClass.name}
            img={dragonClass.slug}
            title={dragonClass.name}
            description={dragonClass.description}
          />
        ))}
      </OverWroughtBackground>
    </Classes>
  );
};

export default DragonClasses;
